import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { Link, useParams } from 'react-router-dom';
import { useGetReportListMutation } from '../../../rtk/services/reportApi';
import Loader from '../../../components/loader';

function ReportedDetails(props) {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetReportListMutation();
    const getItemList = useCallback(() => {
        getList({
            reported_id: id,
        });
    }, [id, getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data?.[0]);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resLoading && (<Loader />)}
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">report details</h5>
                    <div className="action_btns">
                        <button type="button" className="btn btn-primary"> <i className="bi bi-person-check-fill" /> mark as resolved</button>
                        <button type="button" className="btn btn-primary"> <i className="fas fa-lock" /> block reported user</button>
                        <button type="button" className="btn btn-primary"> <i className="fas fa-unlock" /> un block reported user</button>
                    </div>
                </div>
                <div className="card-body details_style">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-group">
                                <label>id</label>
                                <p className="mb-0">{id}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-group">
                                <label>status</label>
                                <p className="mb-0">open</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-group">
                                <label>reported user</label>
                                <p className="mb-0">
                                    <Link to={"/user/detail/1"} className='link-color'>katy</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-group">
                                <label>reporter</label>
                                <p className="mb-0">
                                    <Link to={"/user/detail/1"} className='link-color'>pery</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-group">
                                <label>reported on</label>
                                <p className="mb-0">open</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-group no-detail-style mb-0">
                                <label>reason</label>
                                <p className="mb-0">open</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default ReportedDetails;