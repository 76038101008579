import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useAddUpdateSeniorityLevelMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import Input from '../helper/Input';
import { getSeniorityType } from '../../helper';

const initialValues = {
    type: "",
    title: "",
    price: "",
    status: "",
};
const validationSchema = Yup.object().shape({
    type: Yup.string().required("type is required. please select your type."),
    title: Yup.string().required("title is required. please enter your title."),
    price: Yup.string().required("price is required. please enter your price."),
    status: Yup.string(),
});

function AddEditSeniorityModal(props) {
    const [formData, setFormData] = useState(initialValues);
    const [addEditItem, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useAddUpdateSeniorityLevelMutation()
    useEffect(() => {
        if (props.currentItem) {
            let obj = {
                type: getSeniorityType(props?.currentItem?.health_care),
                title: props?.currentItem?.level || "",
                price: props?.currentItem?.price || 0,
                status: props?.currentItem?.is_active === 1 ? "active" : "in-active",
            }
            setFormData(obj);
        }
    }, [props.currentItem]);
    const handleSubmit = (values) => {
        let payload = {
            "uuid": "",
            "level": values?.title,
            "health_care": props?.seniorityType,
            "is_active": 1
        };
        if (props.currentItem) {
            payload.uuid = props.currentItem?.uuid;
            payload.health_care = props?.currentItem?.health_care;
            payload.is_active = values?.status === "active" ? 1 : values?.status === "in-active" ? 0 : ""
        }
        addEditItem(payload);
    }
    useEffect(() => {
        if (resSuccess) {
            toast.success(resData?.message);
            props.action();
            props.updateList();
        }
    }, [resSuccess, resError, resData?.message, props]);
    useEffect(() => {
        if (props.currentItem == null) {
            initialValues.type = getSeniorityType(props?.seniorityType);
        }
    }, [props.currentItem, props?.seniorityType])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {resFetching && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input type="text" name="type" placeholder="seniority type" disabled={true} />
                            <Input type="text" name="title" placeholder="title" />
                            {props.currentItem === null && (
                                <Input type="text" name="price" placeholder="price in job opening" />
                            )}
                            {props.currentItem !== null && (
                                <div className='text-group'>
                                    <div className="row">
                                        <div className="col-lg-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="active">
                                                    <Field type="radio" id="active" className="form-check-input me-2" name="status" value={"active"} />
                                                    <span>active</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="inActive">
                                                    <Field type="radio" id="inActive" className="form-check-input me-2" name="status" value={"in-active"} />
                                                    <span>in-active</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <ErrorMessage name="status" component="div" className='invalid-feedback d-block' />
                                </div>
                            )}
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddEditSeniorityModal;