import React, { useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import UserTabs from '../components/userTabs';
import { Link, useParams } from 'react-router-dom';
import { useJobSoftSkillMutation } from '../../../../rtk/services/userApi';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import moment from 'moment';

function JsSoftSkills(props) {
    const { userId } = useParams();
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useJobSoftSkillMutation();
    useEffect(() => {
        getList({
            user_uuid: userId
        });
    }, [getList, userId])
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resData, resSuccess]);
    const groupedData = item?.soft_skill?.[0]?.data?.reduce((result, elm) => {
        const domainName = elm.domain_name;
        const facetName = elm.facet_name;
        const existingDomain = result.find(item => item.domainName === domainName);

        if (existingDomain) {
            const existingFacet = existingDomain.facetNames.find(facet => facet.facetName === facetName);
            if (existingFacet) {
                existingFacet.score += elm.score; // Assuming score is a numeric value
            } else {
                existingDomain.facetNames.push({ facetName, score: elm.score });
            }
        } else {
            result.push({
                domainName,
                facetNames: [{ facetName, score: elm.score }]
            });
        }

        return result;
    }, []);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-body pb-0">
                    <UserTabs />
                    {/*  */}
                    {item?.soft_skill?.length > 0 && (
                        <div className="card mb-3">
                            <div className="card-header">
                                <h5 className="card-title">test date: {item?.soft_skill?.[0]?.data?.[0]?.test_date && (moment.utc(item?.soft_skill?.[0]?.data?.[0]?.test_date).local().format("DD MMM YYYY"))}</h5>
                                <div className="action_btns">
                                    <Link to={`/user/soft-skill/details/${userId}`} className='btn btn-primary'>see details soft skills test</Link>
                                </div>
                            </div>
                            <div className="card-body pb-0">
                                {groupedData?.map((elm, i) => (
                                    <div className="card mb-3" key={i}>
                                        <div className="card-header">
                                            <h5 className="card-title">Domain:&nbsp;{elm?.domainName || "-"}</h5>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="table-responsive">
                                                <div className="inner" />
                                                <table className="table table-striped no_wrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Facet Name</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {elm?.facetNames?.map((facet, i) => (
                                                            <tr key={i}>
                                                                <td data-label="Facet Name">{facet?.facetName || "-"}</td>
                                                                <td data-label="Score">{facet?.score || "-"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {item?.soft_skill?.length === 0 && (<div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>)}
                    {/*  */}
                </div>
            </div>
        </AdminLayout>
    );
}

export default JsSoftSkills;