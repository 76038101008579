import React, { useEffect } from 'react';
import ModalLayout from '../layouts/modalLayout';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useState } from 'react';
import Input from '../helper/Input';
import Select from '../helper/Select';
import { useAddSoftSkillsMutation, useGetSkillsDomainListMutation, useGetSkillsFacetListMutation, useUpdateSoftSkillsMutation } from '../../rtk/services/skillsApi';
import Loader from '../loader';
import { getModifiedSelectOptions, intToBool } from '../../helper';

const initialValues = {
    questionText: "",
    questionDomain: "",
    questionFacet: "",
    questionScore: "",
    pairedQuestionText: "",
    pairedQuestionDomain: "",
    pairedQuestionFacet: "",
    pairedQuestionScore: "",
    status: "",
};
const validationSchema = Yup.object().shape({
    questionText: Yup.string().required("text is required. please enter your text."),
    questionDomain: Yup.string().required("domain name is required. please select your domain name."),
    questionFacet: Yup.string().required("facet name is required. please select your facet name."),
    questionScore: Yup.string().required("scoring key is required. please select your scoring key."),

    pairedQuestionText: Yup.string().required("paired text is required. please enter your text."),
    pairedQuestionDomain: Yup.string().required("paired domain name is required. please select your domain name."),
    pairedQuestionFacet: Yup.string().required("paired facet name is required. please select your facet name."),
    pairedQuestionScore: Yup.string().required("paired scoring key is required. please select your scoring key."),
    status: Yup.string(),
});

function AddEditSoftSkillsModal(props) {
    const [formData, setFormData] = useState(initialValues);
    const [addSoftSkills, addResponse] = useAddSoftSkillsMutation();
    const [updateSoftSkills, updateResponse] = useUpdateSoftSkillsMutation();
    const [getDomainList, { data: domainList, isSuccess: domainSuccess, isLoading: domainLoading }] = useGetSkillsDomainListMutation();
    const [getFacetList, { data: facetList, isSuccess: facetSuccess, isLoading: facetLoading }] = useGetSkillsFacetListMutation();
    const [getFacetPairedList, { data: facetPairedList, isSuccess: pairedFacetSuccess, isLoading: facetPairedLoading }] = useGetSkillsFacetListMutation();
    useEffect(() => { getDomainList(); }, [getDomainList]);
    useEffect(() => {
        if (props.currentItem) {
            const currentObj = props.currentItem;
            getFacetList({ domain_id: currentObj?.questions?.[0]?.domain_id });
            getFacetPairedList({ domain_id: currentObj?.questions?.[1]?.domain_id });
        }
    }, [getFacetList, getFacetPairedList, props.currentItem])
    useEffect(() => {
        if (props.currentItem) {
            const currentObj = props.currentItem;
            let obj = {
                questionText: currentObj?.questions?.[0]?.text,
                questionDomain: currentObj?.questions?.[0]?.domain_id,
                questionFacet: currentObj?.questions?.[0]?.facet_id,
                questionScore: intToBool(currentObj?.questions?.[0]?.scoring_key) ? "plus" : "minus",
                pairedQuestionText: currentObj?.questions?.[1]?.text,
                pairedQuestionDomain: currentObj?.questions?.[1]?.domain_id,
                pairedQuestionFacet: currentObj?.questions?.[1]?.facet_id,
                pairedQuestionScore: intToBool(currentObj?.questions?.[1]?.scoring_key) ? "plus" : "minus",
                status: intToBool(currentObj?.isEjecting) ? "active" : "inactive",
            }
            setFormData(obj);
        }
    }, [props.currentItem, domainSuccess, facetSuccess, pairedFacetSuccess]);

    const handleGetFacets = (e) => {
        getFacetList({ domain_id: e.target.value });
    }
    const handleGetSecondFacets = (e) => {
        getFacetPairedList({ domain_id: e.target.value });
    }
    const handleSubmit = (values) => {
        let obj = {
            questions: [
                {
                    text: values?.questionText,
                    domain_id: values?.questionDomain,
                    facet_id: values?.questionFacet,
                    scoring_key: values?.questionScore === "plus" ? 1 : values?.questionScore === "minus" ? 0 : ""
                },
                {
                    text: values?.pairedQuestionText,
                    domain_id: values?.pairedQuestionDomain,
                    facet_id: values?.pairedQuestionFacet,
                    scoring_key: values?.pairedQuestionScore === "plus" ? 1 : values?.pairedQuestionScore === "minus" ? 0 : ""
                }
            ],
            is_active: values?.status === "active" ? 1 : values?.status === "inactive" ? 0 : 1
        }

        if (props.currentItem) {
            obj["card_id"] = props.currentItem?.card_number;
            console.log(obj, "obj")
            updateSoftSkills(obj);
        } else {
            console.log(obj, "obj")
            addSoftSkills(obj);
        }
    }
    useEffect(() => {
        if (addResponse.isSuccess || updateResponse.isSuccess) {
            props.action();
            props.updateList();
        }
    }, [addResponse.isSuccess, props, updateResponse.isSuccess])
    const updatedDomainList = getModifiedSelectOptions({ trueState: domainSuccess, data: domainList?.data, value: "id", title: "domain_name" });
    const updatedFacetList = getModifiedSelectOptions({ trueState: facetSuccess, data: facetList?.data, value: "facet_id", title: "facet_name" });
    const pairedFacetList = getModifiedSelectOptions({ trueState: pairedFacetSuccess, data: facetPairedList?.data, value: "facet_id", title: "facet_name" });
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title} size={"xl"}>
            {(addResponse.isLoading || updateResponse.isLoading || facetLoading || domainLoading || facetPairedLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className='text-white text-center mb-2'>first question details</h5>
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="questionText" className='ms-2'>text</label>
                                            <Input type="textarea" name="questionText" placeholder="text" />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="questionDomain" className='ms-2'>domain name</label>
                                            <Select type="select" name="questionDomain" placeholder="" data={updatedDomainList} onChangeFunc={handleGetFacets} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="questionFacet" className='ms-2'>facet name</label>
                                            <Select type="select" name="questionFacet" placeholder="" data={updatedFacetList} />
                                        </div>
                                        <div className="col-12">
                                            <div className='text-group'>
                                                <label htmlFor="questionScore" className='ms-2'>scoring key</label>
                                                <div className="row">
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="plus">
                                                                <Field type="radio" id="plus" className="form-check-input me-2" name="questionScore" value={"plus"} />
                                                                <span>plus (+)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="minus">
                                                                <Field type="radio" id="minus" className="form-check-input me-2" name="questionScore" value={"minus"} />
                                                                <span>minus (-)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="questionScore" component="div" className='invalid-feedback d-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h5 className='text-white text-center mb-2'>paired question details</h5>
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="pairedQuestionText" className='ms-2'>text</label>
                                            <Input type="textarea" name="pairedQuestionText" placeholder="text" />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="pairedQuestionDomain" className='ms-2'>domain name</label>
                                            <Select type="select" name="pairedQuestionDomain" placeholder="" data={updatedDomainList} onChangeFunc={handleGetSecondFacets} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="pairedQuestionFacet" className='ms-2'>facet name</label>
                                            <Select type="select" name="pairedQuestionFacet" placeholder="" data={pairedFacetList} />
                                        </div>
                                        <div className="col-12">
                                            <div className='text-group'>
                                                <label htmlFor="pairedQuestionScore" className='ms-2'>scoring key</label>
                                                <div className="row">
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="pairedPlus">
                                                                <Field type="radio" id="pairedPlus" className="form-check-input me-2" name="pairedQuestionScore" value={"plus"} />
                                                                <span>plus (+)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="pairedMinus">
                                                                <Field type="radio" id="pairedMinus" className="form-check-input me-2" name="pairedQuestionScore" value={"minus"} />
                                                                <span>minus (-)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="pairedQuestionScore" component="div" className='invalid-feedback d-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {props.currentItem !== null && (
                                    <div className="col-12">
                                        <div className='text-group'>
                                            <label htmlFor="status" className='ms-2'>status</label>
                                            <div className="row">
                                                <div className="col-lg-auto">
                                                    <div className="custom_checkbox">
                                                        <label htmlFor="active">
                                                            <Field type="radio" id="active" className="form-check-input me-2" name="status" value={"active"} />
                                                            <span>active</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <div className="custom_checkbox">
                                                        <label htmlFor="inActive">
                                                            <Field type="radio" id="inActive" className="form-check-input me-2" name="status" value={"inactive"} />
                                                            <span>in-active</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage name="status" component="div" className='invalid-feedback d-block' />
                                        </div>
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className='d-flex justify-content-end gap-10'>
                                        <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                        <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout>
    );
}

export default AddEditSoftSkillsModal;