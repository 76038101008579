import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import SharedWidgets from '../../../components/shared/sharedWidgets';
import { constants } from '../../../constants';
import CommonPagination from '../../../components/shared/CommonPagination';
import { useDebounce } from 'use-debounce';
import { useDeleteUserMutation, useGetUserListDataMutation, useUpdateUserStatusMutation } from '../../../rtk/services/userApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { customSwalFire, getDecodedCountryCode, getEmailType, getPhoneNumber, getStatusType, getUserType, intToBool, textTitleCase } from '../../../helper';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAll } from '../../../rtk/feature/commonSlice';
import ExportButton from '../../../components/helper/exportButton';

function UserList(props) {
    const dispatch = useDispatch();
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [searchKey, setSearchKey] = useState('');
    const [searchValue] = useDebounce(searchKey, 1000);
    const [userType, setUserType] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [updateItem, updateItemResponse] = useUpdateUserStatusMutation();
    const [deleteItem, deleteItemResponse] = useDeleteUserMutation();
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading, error: resError }] = useGetUserListDataMutation();
    const getUserList = useCallback(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            search_keyword: searchValue,
            user_type: userType,
            user_status: userStatus,
        });
    }, [getList, pageLimit, pageOffset, searchValue, userStatus, userType]);
    useEffect(() => {
        dispatch(clearAll())
        getUserList();
    }, [getUserList, dispatch]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resError, resSuccess]);
    const handleActions = (event, item, type) => {
        let title = "";
        let textMessage = "";
        if (type === constants?.actionTypes?.blockUnblock) {
            if (!item.is_active) {
                title = "Unblock?"
                textMessage = "Are you sure you want to Unblock?"
            } else {
                title = "Block?"
                textMessage = "Are you sure you want to Block?"
            }
        } else if (type === constants?.actionTypes?.delete) {
            title = "Delete?"
            textMessage = "Are you sure you want to Delete?"
        }
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                (event.target.checked) ? event.target.checked = true : event.target.checked = false;
                if (result.value) {
                    if (type === constants?.actionTypes?.blockUnblock) {
                        updateItem({ user_uuid: item?.uuid, is_active: item.is_active === 1 ? 0 : 1 })
                    } else if (type === constants?.actionTypes?.delete) {
                        deleteItem({ user_uuid: item?.uuid })
                    }
                }
            } else {
                (event.target.checked) ? event.target.checked = false : event.target.checked = true;
            }
        });
    }
    useEffect(() => {
        if (updateItemResponse.isSuccess || deleteItemResponse.isSuccess) {
            getUserList();
            updateItemResponse.reset();
            deleteItemResponse.reset();
        }
    }, [deleteItemResponse, getUserList, updateItemResponse])
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <AdminLayout>
            {(resLoading || updateItemResponse.isLoading || deleteItemResponse.isLoading) && (<Loader />)}
            <SharedWidgets title={"All Users"} show={5} />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">User list ({totalCount})</h5>
                    <div className="action_btns">
                        <ExportButton type={constants.exportKeys.users} />
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="form-group">
                                <select className="form-control form-select" onChange={(e) => { setUserStatus(e.target.value); setPageOffset(0) }}>
                                    {constants.statusType?.map((item, i) => (
                                        <option value={item?.value} key={i}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="form-group">
                                <select className="form-control form-select" onChange={(e) => { setUserType(e.target.value); setPageOffset(0) }}>
                                    {constants.userType?.map((item, i) => (
                                        <option value={item?.value} key={i}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <input type="text" className='form-control' placeholder='Search by name, email, phone number' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body py-0">
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>Sr. no.</th>
                                    <th>name</th>
                                    <th>user type</th>
                                    <th>email</th>
                                    <th>phone number</th>
                                    <th>status</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="Sr. no.">{(pageOffset) * pageLimit + i + 1}</td>
                                            <td data-label="name"><Link to={"/user/detail/" + item?.uuid} className='link-color'>{textTitleCase(item?.first_name)} {textTitleCase(item?.last_name)}</Link></td>
                                            <td data-label="user type">{item?.already_swapped ? constants.userTypesSort.both : getUserType(item?.user_type_Id)}</td>
                                            <td data-label="email">{getEmailType(item?.user_type_Id, item)}</td>
                                            <td data-label="phone number">{getDecodedCountryCode(item?.country_code)} {item && (getPhoneNumber(item?.user_type_Id, item))}</td>
                                            <td data-label="status">{getStatusType(item?.is_active)}</td>
                                            <td data-label="action">
                                                <div className="action_wrap">
                                                    <label className="switch">
                                                        <input type="checkbox" defaultChecked={intToBool(item?.is_active)} onChange={(e) => handleActions(e, item, constants?.actionTypes?.blockUnblock)} />
                                                        <span className="slider round" />
                                                    </label>
                                                    <Link to={"/user/detail/" + item?.uuid} className="btn btn-icon">
                                                        <i className="bi-eye" />
                                                    </Link>
                                                    <button type="button" className="btn btn-icon" onClick={(e) => handleActions(e, item, constants?.actionTypes?.delete)}>
                                                        <i className="bi-trash" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
        </AdminLayout >
    );
}

export default UserList;