import React, { useCallback, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import BackButton from '../../../../components/helper/backButton';
import AddEditCompanySizeModal from '../../../../components/modals/AddEditCompanySizeModal';
import { useGetHiringQuestionSingleMutation } from '../../../../rtk/services/lookupsApi';
import { useEffect } from 'react';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import Loader from '../../../../components/loader';

function HiringCompanySize(props) {
    const [itemList, setItemList] = useState([]);
    const [addEditModal, setAddEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isLoading: resLoading, isSuccess: resSuccess }] = useGetHiringQuestionSingleMutation();
    const getItemList = useCallback(() => {
        getList({
            hiring_type: "company-size"
        });
    }, [getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
        }
    }, [resSuccess, resData]);
    return (
        <AdminLayout>
            {resLoading && (<Loader />)}
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">company size</h5>
                    <div className="action_btns">
                        <BackButton />
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>sr. no.</th>
                                    <th>company size</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="sr. no.">{i + 1}</td>
                                            <td data-label="company size">{item?.name || "-"}</td>
                                            <td data-label="action">
                                                <div className="action_wrap">
                                                    <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                                                        <i className="bi-pencil" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {addEditModal && (<AddEditCompanySizeModal state={addEditModal}
                action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} company size`} currentItem={currentItem} updateList={() => getItemList()} />)}
        </AdminLayout>
    );
}

export default HiringCompanySize;