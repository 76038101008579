import React, { useState, useEffect } from 'react';
import ModalLayout from '../layouts/modalLayout';
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import Input from '../helper/Input';
import Select from '../helper/Select';
import { constants } from '../../constants';
import { useAddAdminUserMutation, useUpdateAdminUserMutation, useGetUserPermissionMutation } from '../../rtk/services/adminUserApi';
import Loader from '../loader';
import { getModifiedSelectOptions } from '../../helper';

const initialValues = {
    name: "",
    email: "",
    mobileNo: "",
    // countryCode: "",
    role: "",
    status: ""
};
// .required("country code is required. please enter your country code")
const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required. please enter your name."),
    email: Yup.string().email(constants?.validations?.invalidEmail).required("email is required. please enter your email."),
    mobileNo: Yup.string().required("mobile no. is required. please enter your mobile no."),
    // countryCode: Yup.string(),
    status: Yup.string(),
    role: Yup.string().required("role is required. please select your role."),
});

function AddEditAdminUsersModal(props) {
    console.log("=====", props.currentItem);
    const [formData, setFormData] = useState(initialValues);
    const mutationHook = props.currentItem ? useUpdateAdminUserMutation : useAddAdminUserMutation;
    // const [addUpdateUser, addUpdateUserResponse] = useAddUpdateAdminUserMutation();
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetUserPermissionMutation();
    const [updateUserPermissionMutation, { data: updateData, isSuccess: updateSuccess, isLoading: updateLoading, error: updateError },] = mutationHook();

    console.log("---------", updateData);
    useEffect(() => {
        getList();
    }, [getList]);
    useEffect(() => {
        if (props.currentItem) {
            let user = props.currentItem;
            let obj = {
                name: user?.first_name || "",
                email: user?.work_email || "",
                mobileNo: user?.mobile_number || "",
                // countryCode: user?.first_name || "",
                role: user?.user_role_id || "",
                status: user?.status
            }
            setFormData(obj);
        }
    }, [props.currentItem])

    const handleSubmit = async (values) => {
        let obj;
        console.log("values", values);
        if (!props.currentItem) {
            obj = {
                // uuid: "",
                name: values?.name,
                work_email: values?.email,
                mobile_number: values?.mobileNo,
                // role: parseInt(values?.role),
                // status: values?.status,
                admin_role_id: parseInt(values?.role)
            };
        } else {
            console.log("\\\\\\\\", props.currentItem);
            obj = {
                uuid: props.currentItem?.uuid,
                // name: values?.name,
                app_user_id: props.currentItem?.app_user_id,
                // work_email: values?.email,
                // mobile_number: values?.mobileNo,
                // role: parseInt(values?.role),
                is_active: values?.status === "active" ? 1 : 0,
            };
        }

        console.log(obj);
        await updateUserPermissionMutation(obj);
        setFormData(obj);
    };


    useEffect(() => {
        if (updateSuccess) {
            props.updateList();
            props.action();
        }
    }, [updateSuccess, updateData])
    // useEffect(() => {
    //     console.log("addUpdateUserResponse", addUpdateUserResponse);
    //     if (addUpdateUserResponse.isSuccess) {
    //         addUpdateUserResponse.reset();
    //         props.updateList();
    //         props.action();
    //     }
    // }, [addUpdateUserResponse, addUpdateUserResponse.isSuccess, addUpdateUserResponse.isError, props]);


    const updatedUserType = getModifiedSelectOptions({ trueState: resSuccess, data: resData?.data, value: "user_role_id", title: "role_name" });
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {(updateLoading || resLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="name" className='ms-2'>name</label>
                                    <Input type="text" name="name" placeholder="name" />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="email" className='ms-2'>email</label>
                                    <Input type="email" name="email" placeholder="email" />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="mobileNo" className='ms-2'>mobile number</label>
                                    <Input type="text" name="mobileNo" placeholder="mobile number" />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="role" className='ms-2'>role</label>
                                    <Select type="select" name="role" placeholder="" data={updatedUserType} />
                                </div>
                                {props?.currentItem && (
                                    <div className="col-12">
                                        <div className='text-group'>
                                            <label htmlFor="status" className='ms-2'>status</label>
                                            <div className="row">
                                                <div className="col-lg-auto">
                                                    <div className="custom_checkbox">
                                                        <label htmlFor="active">
                                                            <Field type="radio" id="active" className="form-check-input me-2" name="status" value={"active"} />
                                                            <span>active</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <div className="custom_checkbox">
                                                        <label htmlFor="inActive">
                                                            <Field type="radio" id="inActive" className="form-check-input me-2" name="status" value={"inactive"} />
                                                            <span>in-active</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage name="status" component="div" className='invalid-feedback d-block' />
                                        </div>
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className='d-flex justify-content-end gap-10'>
                                        <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                        <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Save" : "Send Invite"}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout>
    );
}

export default AddEditAdminUsersModal;