import React, { useState, useCallback, useEffect } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CommonPagination from '../../../../components/shared/CommonPagination';
import AddEditSeniorityModal from '../../../../components/modals/AddEditSeniorityModal';
import BackButton from '../../../../components/helper/backButton';
import { useGetHiringQuestionSingleMutation, useUpdateSeniorityOrderMutation } from '../../../../rtk/services/lookupsApi';
import Loader from '../../../../components/loader';
import { getStatusType } from '../../../../helper';
import { constants } from '../../../../constants';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';

const ItemType = 'TABLE_ROW';

const TableRow = ({ item, index, moveRow, setAddEditModal, setCurrentItem, changeOrderToggle }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveRow(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <tr ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <td data-label="seniority id">{item?.id || "-"}</td>
            <td data-label="seniority level">{item?.level || "-"}</td>
            <td data-label="status">{getStatusType(item?.is_active)}</td>
            <td data-label="action">
                <div className="action_wrap">
                    {changeOrderToggle && (
                        <button type="button" className="btn btn-icon" >
                            <i className="bi-arrow-down-up" />
                        </button>
                    )}
                    {!changeOrderToggle && (
                        <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                            <i className="bi-pencil" />
                        </button>
                    )}
                </div>
            </td>
        </tr>
    );
};

function HiringSeniorities(props) {
    const [addEditModal, setAddEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(constants.common.limit);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [changeOrderToggle, setChangeOrderToggle] = useState(false);
    const [getList, { data: resData, isLoading: resLoading, isSuccess: resSuccess }] = useGetHiringQuestionSingleMutation();
    const [updateOrder, { isLoading: orderLoading, isSuccess: orderSuccess }] = useUpdateSeniorityOrderMutation();

    const getItemList = useCallback(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            hiring_type: 'seniority',
        });
    }, [getList, pageLimit, pageOffset]);

    useEffect(() => {
        getItemList();
    }, [getItemList]);

    useEffect(() => {
        if (resSuccess) {
            const updatedItems = resData?.data?.filter((elm) => elm?.health_care === 0);
            setItemList(updatedItems);
            setTotalCount(resData?.total_records);
        }
    }, [resSuccess, resData]);

    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    };

    const moveRow = (dragIndex, hoverIndex) => {
        const updatedList = [...itemList];
        const draggedItem = updatedList[dragIndex];

        updatedList.splice(dragIndex, 1);
        updatedList.splice(hoverIndex, 0, draggedItem);

        setItemList(updatedList);
    };
    const handleSaveOrder = async () => {
        let filteredIds = itemList?.filter((elm) => elm?.id)?.map((item) => item.id);
        await updateOrder({
            seniority_ids: filteredIds
        });
    }
    useEffect(() => {
        if (orderSuccess) {
            getItemList();
            setChangeOrderToggle(false);
        }
    }, [getItemList, orderSuccess])

    return (
        <DndProvider backend={HTML5Backend}>
            <AdminLayout>
                {(resLoading || orderLoading) && <Loader />}
                <div className="custom_tabs">
                    <div className="nav-tabs nav mx-auto">
                        <Link to="/lookups/hiring/seniorities/" className="nav-link active">
                            seniority type: industrial
                        </Link>
                        <Link to="/lookups/hiring/seniorities/medical" className="nav-link">
                            seniority type: medical
                        </Link>
                    </div>
                </div>
                <div className="card has_col">
                    <div className="card-header">
                        <h5 className="card-title">seniority level ({totalCount || 0})</h5>
                        <div className="action_btns">
                            {!changeOrderToggle && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setChangeOrderToggle(true);
                                        setPageOffset(0);
                                        setPageLimit(totalCount);
                                        getItemList();
                                    }}
                                >
                                    <i className="bi bi-arrow-down-up" /> Change order
                                </button>
                            )}
                            {changeOrderToggle && (
                                <button type="button" className="btn btn-primary" onClick={() => { handleSaveOrder() }}>
                                    <i className="bi bi-save" /> Save
                                </button>
                            )}
                            {changeOrderToggle && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setChangeOrderToggle(false);
                                        setPageOffset(0);
                                        setPageLimit(constants.common.limit);
                                        getItemList();
                                    }}
                                >
                                    <i className="bi bi-x-lg" /> cancel
                                </button>
                            )}
                            {!changeOrderToggle && (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setAddEditModal(true);
                                            setCurrentItem(null);
                                        }}
                                    >
                                        <i className="bi bi-plus" /> Add
                                    </button>
                                    <button type="button" className="btn btn-primary">
                                        <i className="bi bi-file-earmark-arrow-down-fill" /> Export
                                    </button>
                                </>
                            )}
                            {!changeOrderToggle && <BackButton />}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive ">
                            <div className="inner" />
                            <table className="table table-striped no_wrap">
                                <thead>
                                    <tr>
                                        <th>seniority id</th>
                                        <th>seniority level</th>
                                        <th>status</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {changeOrderToggle ? itemList?.map((item, i) => (
                                        <TableRow
                                            key={item?.id}
                                            item={item}
                                            index={i}
                                            moveRow={moveRow}
                                            setAddEditModal={setAddEditModal}
                                            setCurrentItem={setCurrentItem}
                                            changeOrderToggle={changeOrderToggle}
                                        />
                                    ))
                                        : itemList.length > 0 ? itemList?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="seniority id">{item?.id || "-"}</td>
                                                <td data-label="seniority level">{item?.level || "-"}</td>
                                                <td data-label="status">{getStatusType(item?.is_active)}</td>
                                                <td data-label="action">
                                                    <div className="action_wrap">
                                                        {changeOrderToggle && (
                                                            <button type="button" className="btn btn-icon" >
                                                                <i className="bi-arrow-down-up" />
                                                            </button>
                                                        )}
                                                        {!changeOrderToggle && (
                                                            <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                                                                <i className="bi-pencil" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <NoRecordsFound />}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer">
                        <CommonPagination hitAction={handlePagination} total={totalCount || 0} pageLimit={pageLimit} currentPage={pageOffset} />
                    </div>
                </div>

                {addEditModal && (
                    <AddEditSeniorityModal
                        state={addEditModal}
                        action={() => {
                            setAddEditModal(false);
                            setCurrentItem(null);
                        }}
                        title={`${currentItem !== null ? 'Edit' : 'Add'} seniority level`}
                        seniorityType={0}
                        currentItem={currentItem}
                        updateList={() => getItemList()}
                    />
                )}
            </AdminLayout>
        </DndProvider>
    );
}

export default HiringSeniorities;
