import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import UserTabs from '../components/userTabs';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { constants } from '../../../../constants';
import { useUserPaymentHistoryMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import CommonPagination from '../../../../components/shared/CommonPagination';
import moment from 'moment';
import { intToBool, priceFormat } from '../../../../helper';

function HmJobPayments(props) {
    const { userId } = useParams();
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [totalCount, setTotalCount] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserPaymentHistoryMutation();
    useEffect(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            user_uuid: userId
        });
    }, [getList, userId, pageLimit, pageOffset]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Job Payments</h5>
                </div>
                <div className="card-body pb-0">
                    <UserTabs />
                    <div className="row">
                        {itemList?.length > 0 ? itemList?.map((item, i) => (
                            <div className="col-12" key={i}>
                                <Alert variant="dark" className='payment-card'>
                                    <Alert.Heading className='d-flex justify-content-between mb-3 h6'><p className='mb-0'>Job id &nbsp;{item?.job_id || "-"} - <>{item?.job_info?.title || "-"}</></p> {item?.created_at ? moment.utc(item?.payment_date).local().format("DD MMM YYYY") : "-"}</Alert.Heading>
                                    <div className='d-flex justify-content-between'>
                                        <span className="badge rounded-pill bg-info me-2">{intToBool(item?.status) ? "Job search" : "match feedback"} payment</span>
                                        <div>
                                            <span className="badge rounded-pill bg-dark me-2">{item?.total_payment_amount ? priceFormat(item?.total_payment_amount) : "-"}</span>
                                            <span className="badge rounded-pill bg-success me-2">paid</span>
                                        </div>
                                    </div>
                                </Alert>
                            </div>
                        )) : <div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>}
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>

        </AdminLayout>
    );
}

export default HmJobPayments;