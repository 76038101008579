import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const softSkillsApi = createApi({
    reducerPath: "softSkillsApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getSoftSkillsList: builder.mutation({
            query: (body) => {
                // &is_invited_peer=${body?.is_invited_peer}
                const url = `page_limit=${body?.page_limit || 10}&is_invited_peer=${body?.is_invited_peer}&page_offset=${body?.page_offset || 1}&skill_test_date=${body?.skill_test_date || ""}&user_type=${body?.user_type || ""}&search_keyword=${body?.search_keyword || ""}`;
                return {
                    url: `/admin/soft-skill/user?${url}`
                }
            },
        }),
        getSoftSkillsUserDetails: builder.mutation({
            query: (body) => {
                // 
                const url = `job_id=${body?.job_id || ""}&js_history_id=${body?.js_history_id || ""}&user_id=${body?.user_id || ""}&user_type=${body?.user_type || ""}`;
                return {
                    url: `/admin/soft-skill/user/details?${url}`
                }
            },
        }),
    }),
});

export const {
    useGetSoftSkillsListMutation,
    useGetSoftSkillsUserDetailsMutation
} = softSkillsApi;
