import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from "./rtk/store";

import Login from './pages/auth/login';
import ForgotPassword from './pages/auth/forgotPassword';
import ResetPassword from './pages/auth/resetPassword';
import Profile from './pages/admin/profile';
import Dashboard from './pages/admin/dashboard';
import UserList from './pages/admin/users';
import Loader from './components/loader';
import IndustryList from './pages/admin/lookups/industryList';
import DepartmentList from './pages/admin/lookups/departmentList';
import RoleList from './pages/admin/lookups/roleList';
import UserDetails from './pages/admin/users/details';
import UserCompanyDetails from './pages/admin/users/hm/company';
import HmJobPosts from './pages/admin/users/hm/hm-job-posts';
import HmJobDetail from './pages/admin/users/hm/hm-job-details';
import HmJobPayments from './pages/admin/users/hm/hm-job-payments';
import HmJobFeedbacks from './pages/admin/users/hm/hm-job-feedbacks';
import HmJobFeedbackDetail from './pages/admin/users/hm/hm-job-feedback-detail';
import HmJobSoftSkills from './pages/admin/users/hm/hm-job-soft-skills';
import HmJobSoftSkillDetail from './pages/admin/users/hm/hm-job-soft-skill-detail';
import HmJobMatches from './pages/admin/users/hm/hm-job-matches';
import JsJobDetails from './pages/admin/users/hm/js-job-details';
import JsJobSoftSkills from './pages/admin/users/hm/js-job-soft-skills';
import JsJobSoftSkillDetails from './pages/admin/users/hm/js-job-soft-skill-detail';
import JsJobInterviewDetails from './pages/admin/users/hm/js-job-interview-details';
import JsJobFeedback from './pages/admin/users/hm/js-job-feedback';

import JsSoftSkills from './pages/admin/users/js/js-soft-skills';
import JsSoftSkillDetails from './pages/admin/users/js/js-soft-skill-details';
import JsJobPosts from './pages/admin/users/js/js-job-posts';
import SoftSkillsList from './pages/admin/skill-quiz';
import SoftSkillScoreKey from './pages/admin/skill-quiz/score-key';
import InterviewQuestions from './pages/admin/lookups/interviewQuestions';
import AddEditInterviewQuestions from './pages/admin/lookups/addEditInterviewQuestions';
import ReportedList from './pages/admin/reported/list';
import ReportedDetails from './pages/admin/reported/detail';
import InvoiceList from './pages/admin/invoice/list';
import AddEditRole from './pages/admin/admin/addEditRole';
import AdminList from './pages/admin/admin/list';
import AdminRoles from './pages/admin/admin/roles';
import CompanyInfo from './pages/admin/pricing/company-info';
import JobOpenings from './pages/admin/pricing/job-openings';
import IdealCandidate from './pages/admin/pricing/ideal-candidate';
import SoftSkillTest from './pages/admin/pricing/soft-skill-test';
import MatchFeedback from './pages/admin/pricing/match-feedback';
import SoftSkillsListing from './pages/admin/soft-skills';
import SoftSkillsListingList from './pages/admin/soft-skills/list';
import SoftSkillsDetail from './pages/admin/soft-skills/detail';
import JobsList from './pages/admin/jobs';
import JsJobSearchDetail from './pages/admin/users/js/js-job-search-details';
import JsJobSearchRefineDetail from './pages/admin/users/js/js-job-search-refine-details';
import JsJobMatches from './pages/admin/users/js/js-job-matches';
import HmJobSearchDetails from './pages/admin/users/js/hm-job-search-details';
import HmSoftSkillMatch from './pages/admin/users/js/hm-soft-skill-match';
import HmSoftSkillMatchDetail from './pages/admin/users/js/hm-soft-skill-match-detail';
import HmJsJobInterviewDetails from './pages/admin/users/js/hm-js-job-interview-details';
import HmJsJobInterviewFeedback from './pages/admin/users/js/hm-js-job-interview-feedback';
import HiringQuestions from './pages/admin/lookups/hiring-questions';
import HiringGoal from './pages/admin/lookups/hiring-questions/goals';
import HiringTotalCompensation from './pages/admin/lookups/hiring-questions/total-compensation';
import HiringBenefits from './pages/admin/lookups/hiring-questions/benefits';
import HiringCompanySize from './pages/admin/lookups/hiring-questions/company-size';
import HiringCompanyType from './pages/admin/lookups/hiring-questions/company-type';
import HiringDepartments from './pages/admin/lookups/hiring-questions/departments';
import HiringEducationLevel from './pages/admin/lookups/hiring-questions/education-level';
import HiringSoftwareLanguages from './pages/admin/lookups/hiring-questions/languages';
import HiringResponsibilities from './pages/admin/lookups/hiring-questions/responsibilities';
import HiringRoleExperience from './pages/admin/lookups/hiring-questions/role-experience';
import HiringSeniorities from './pages/admin/lookups/hiring-questions/seniorities';
import HiringThisJobIs from './pages/admin/lookups/hiring-questions/this-job-is';
import HiringTotalExperience from './pages/admin/lookups/hiring-questions/total-experience';
import HiringWorkingStyle from './pages/admin/lookups/hiring-questions/working-style';
import HiringSenioritiesMedical from './pages/admin/lookups/hiring-questions/seniorities-medical';
import ExportList from './pages/admin/export/list';
import HmJobUnpaid from './pages/admin/users/hm/hm-job-unpaid';

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        {/* auth */}
                        <Route exact path="/" element={<Login />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/forgot-password" element={<ForgotPassword />} />
                        <Route exact path="/reset-password" element={<ResetPassword />} />
                        <Route exact path="/profile" element={<Profile />} />

                        <Route exact path="/dashboard" element={<Dashboard />} />
                        <Route exact path="/profile" element={<Profile />} />
                        {/*  */}
                        <Route exact path="/users" element={<UserList />} />
                        <Route exact path="/user/detail/:userId" element={<UserDetails />} />
                        <Route exact path="/user/company/:userId" element={<UserCompanyDetails />} />
                        {/* Hm */}
                        <Route exact path="/user/job-posts/:userId" element={<HmJobPosts />} />
                        <Route exact path="/user/job/details/:job_uuid" element={<HmJobDetail />} />
                        <Route exact path="/user/job/soft-skills/:job_uuid" element={<HmJobSoftSkills />} />
                        <Route exact path="/user/job/soft-skill/details/:job_uuid" element={<HmJobSoftSkillDetail />} />
                        <Route exact path="/user/job/matches/:job_uuid" element={<HmJobMatches />} />
                        <Route exact path="/user/job/unpaid/:job_uuid" element={<HmJobUnpaid />} />
                        <Route exact path="/user/job/match/detail/:job_uuid" element={<JsJobDetails />} />
                        <Route exact path="/user/job/match/soft-skills/:job_uuid" element={<JsJobSoftSkills />} />
                        <Route exact path="/user/job/match/soft-skill/details/:job_uuid" element={<JsJobSoftSkillDetails />} />
                        <Route exact path="/user/job/match/interview-details/:job_uuid" element={<JsJobInterviewDetails />} />
                        <Route exact path="/user/job/match/feedback/:job_uuid" element={<JsJobFeedback />} />
                        <Route exact path="/user/payments/:userId" element={<HmJobPayments />} />
                        <Route exact path="/user/feedbacks/:userId" element={<HmJobFeedbacks />} />
                        <Route exact path="/user/feedback/detail/:user_uuid/:job_uuid/:feedback_type" element={<HmJobFeedbackDetail />} />
                        <Route exact path="/user/feedbacks/:userId" element={<HmJobFeedbacks />} />
                        {/* Js */}
                        <Route exact path="/user/soft-skills/:userId" element={<JsSoftSkills />} />
                        <Route exact path="/user/soft-skill/details/:userId" element={<JsSoftSkillDetails />} />
                        <Route exact path="/user/job-searches/:userId" element={<JsJobPosts />} />
                        <Route exact path="/user/job-search/details/:search_id/:user_uuid" element={<JsJobSearchDetail />} />
                        <Route exact path="/user/job-search/refine-details/:search_id/:user_uuid" element={<JsJobSearchRefineDetail />} />
                        <Route exact path="/user/job-search/matches/:search_id/:user_uuid" element={<JsJobMatches />} />
                        <Route exact path="/user/job-search/match/detail/:job_uuid" element={<HmJobSearchDetails />} />
                        <Route exact path="/user/job-search/match/soft-skill/:job_uuid" element={<HmSoftSkillMatch />} />
                        <Route exact path="/user/job-search/match/soft-skill/detail/:job_uuid" element={<HmSoftSkillMatchDetail />} />
                        <Route exact path="/user/job-search/match/interview-detail/:job_uuid" element={<HmJsJobInterviewDetails />} />
                        <Route exact path="/user/job-search/match/interview-feedback/:job_uuid" element={<HmJsJobInterviewFeedback />} />
                        {/* skill quiz */}
                        <Route exact path="/soft-skills-quiz/list" element={<SoftSkillsList />} />
                        <Route exact path="/soft-skills-quiz/score" element={<SoftSkillScoreKey />} />
                        {/* lookups */}
                        <Route exact path="/lookups/industry" element={<IndustryList />} />
                        <Route exact path="/lookups/department/:uuid" element={<DepartmentList />} />
                        <Route exact path="/lookups/role/:uuid" element={<RoleList />} />
                        <Route exact path="/lookups/interview-questions" element={<InterviewQuestions />} />
                        <Route exact path="/lookups/interview-questions/add" element={<AddEditInterviewQuestions />} />
                        <Route exact path="/lookups/interview-questions/edit/:id" element={<AddEditInterviewQuestions />} />

                        <Route exact path="/lookups/hiring" element={<HiringQuestions />} />
                        <Route exact path="/lookups/hiring/goals" element={<HiringGoal />} />
                        <Route exact path="/lookups/hiring/departments" element={<HiringDepartments />} />
                        <Route exact path="/lookups/hiring/seniorities" element={<HiringSeniorities />} />
                        <Route exact path="/lookups/hiring/seniorities/medical" element={<HiringSenioritiesMedical />} />
                        <Route exact path="/lookups/hiring/languages" element={<HiringSoftwareLanguages />} />
                        <Route exact path="/lookups/hiring/responsibilities" element={<HiringResponsibilities />} />
                        <Route exact path="/lookups/hiring/working-style" element={<HiringWorkingStyle />} />
                        <Route exact path="/lookups/hiring/company-size" element={<HiringCompanySize />} />
                        <Route exact path="/lookups/hiring/company-type" element={<HiringCompanyType />} />
                        <Route exact path="/lookups/hiring/role-experience" element={<HiringRoleExperience />} />
                        <Route exact path="/lookups/hiring/total-experience" element={<HiringTotalExperience />} />
                        <Route exact path="/lookups/hiring/education-level" element={<HiringEducationLevel />} />
                        <Route exact path="/lookups/hiring/this-job-is" element={<HiringThisJobIs />} />
                        <Route exact path="/lookups/hiring/benefits" element={<HiringBenefits />} />
                        <Route exact path="/lookups/hiring/total-compensation" element={<HiringTotalCompensation />} />
                        {/* reported */}
                        <Route exact path="/reported" element={<ReportedList />} />
                        <Route exact path="/reported/detail/:id" element={<ReportedDetails />} />
                        {/* invoice */}
                        <Route exact path="/invoice" element={<InvoiceList />} />
                        {/* admin panel */}
                        <Route exact path="/admin/roles" element={<AdminRoles />} />
                        <Route exact path="/admin/role/edit/:id/:roleName" element={<AddEditRole />} />
                        <Route exact path="/admin/list" element={<AdminList />} />
                        {/* pricing */}
                        <Route exact path="/pricing/company-info" element={<CompanyInfo />} />
                        <Route exact path="/pricing/job-openings" element={<JobOpenings />} />
                        <Route exact path="/pricing/ideal-candidate" element={<IdealCandidate />} />
                        <Route exact path="/pricing/soft-skills" element={<SoftSkillTest />} />
                        <Route exact path="/pricing/match-feedback" element={<MatchFeedback />} />
                        {/* soft skill test users */}
                        <Route exact path="/soft-skills-listing" element={<SoftSkillsListing />} />
                        <Route exact path="/soft-skills-listing/list/:userId/:job_id/:js_history_id/:user_type" element={<SoftSkillsListingList />} />
                        <Route exact path="/soft-skills-listing/detail/:id" element={<SoftSkillsDetail />} />
                        {/* jobs */}
                        <Route exact path="/jobs" element={<JobsList />} />
                        {/* export requests */}
                        <Route exact path="/export" element={<ExportList />} />
                    </Routes>
                </Suspense>
            </Router>
            <ToastContainer autoClose="1200" hideProgressBar={true} pauseOnHover={true} newestOnTop={true} />
        </Provider >
    );
}

export default App;
