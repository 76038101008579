import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { constants } from '../../../constants';
import { useDebounce } from 'use-debounce';
import { useDeleteDepartmentMutation, useGetDepartmentListMutation } from '../../../rtk/services/lookupsApi';
import { customSwalFire, getSeniorityType } from '../../../helper';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader';
import CommonPagination from '../../../components/shared/CommonPagination';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { Link, useParams } from 'react-router-dom';
import AddEditDepartmentModal from '../../../components/modals/AddEditDepartmentModal';
import { Alert } from 'react-bootstrap';

function DepartmentList(props) {
    const { uuid } = useParams();
    const [item, setItem] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [addEditModal, setAddEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useGetDepartmentListMutation();
    const [deleteItem, { data: deleteData, isSuccess: deleteSuccess, isLoading: deleteFetching, reset: deleteReset }] = useDeleteDepartmentMutation();
    const getItemList = useCallback(() => {
        getList({
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            industry_uuid: uuid
        });
    }, [getList, searchValue, pageLimit, pageOffset, uuid]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData);
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resError, resSuccess]);
    const handleActions = (item, type) => {
        let title = "";
        let textMessage = "";
        if (type === constants?.actionTypes?.delete) {
            title = "Delete?"
            textMessage = "Are you sure you want to Delete?"
        }
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                deleteItem({ department_uuid: item?.uuid })
            }
        });
    }
    useEffect(() => {
        if (deleteSuccess) {
            toast.success(deleteData?.message);
            deleteReset();
            getItemList();
        }
    }, [deleteData, deleteReset, deleteSuccess, getItemList]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <AdminLayout>
            {(resFetching || deleteFetching) && (<Loader />)}
            <Alert variant="dark">
                <Alert.Heading>{item?.industry_name || "-"}</Alert.Heading>
                <p className='mb-0'>seniority type: {getSeniorityType(item?.seniority_id)}</p>
            </Alert>
            <div className="custom_tabs">
                <div className="nav-tabs nav mx-auto">
                    <Link to={"/lookups/department/" + uuid} className="nav-link active">departments</Link>
                    <Link to={"/lookups/role/" + uuid} className="nav-link">roles</Link>
                </div>
            </div >
            <div className="card has_col">
                <div className="card-header">
                    <h5 className="card-title">Department list ({totalCount})</h5>
                    <div className="action_btns">
                        <button type="button" className="btn btn-primary" onClick={() => { setAddEditModal(true); setCurrentItem(null); }}> <i className="bi bi-plus" /> Add</button>
                        <button type="button" className="btn btn-primary"> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <input type="text" className='form-control' placeholder='Search by department name' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body py-0">
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>Sr. no.</th>
                                    <th>Department name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="Sr. no.">{(pageOffset) * pageLimit + i + 1}</td>
                                            <td data-label="Department name">{item?.title || "-"}</td>
                                            <td data-label="Action">
                                                <div className="action_wrap">
                                                    <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                                                        <i className="bi-pencil" />
                                                    </button>
                                                    <button type="button" className="btn btn-icon" onClick={(e) => handleActions(item, constants?.actionTypes?.delete)}>
                                                        <i className="bi-trash" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
            {addEditModal && (<AddEditDepartmentModal state={addEditModal}
                action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} Department`} currentItem={currentItem} updateList={() => getItemList()} />)}
        </AdminLayout >
    );
}

export default DepartmentList;