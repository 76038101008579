import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

function ContextAwareToggle({ children, eventKey, callback, setCurrentType, type }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Card.Header onClick={() => { decoratedOnClick(); setCurrentType(type); }} className={`cursor-pointer ${isCurrentEventKey ? "active" : ""}`}>
            <h5 className='card-title'>{children} </h5>
            <div className="action_btns">
                <label className='btn btn-primary btn-sm btn-icon mb-0'>
                    <i className={`bi bi-${isCurrentEventKey ? "chevron-up" : "chevron-down"}`} />
                </label>
            </div>
        </Card.Header>
    );
}
export default ContextAwareToggle;