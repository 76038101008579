import React, { useEffect } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import JsJobInfo from '../components/js-job-info';
import HmJobSearchTabs from '../components/hm-job-search-tabs';
import { Alert } from 'react-bootstrap';
import { useState } from 'react';
import { handleCopyItem } from '../../../../helper';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useMatchedSoftSkillsMutation } from '../../../../rtk/services/userApi';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/loader';

function HmSoftSkillMatch(props) {
    const { job_uuid } = useParams();
    const { currentHmJob } = useSelector(({ common }) => common);
    const location = useLocation();
    const [item, setItem] = useState(null);
    const queryParams = new URLSearchParams(location?.search);
    const job_id = queryParams.get('job_id');
    const user_id = queryParams.get('user_id');
    const job_history_id = queryParams.get('job_history_id');
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useMatchedSoftSkillsMutation();
    useEffect(() => {
        getList({
            js_history_id: job_history_id,
            job_id: job_id,
            user_id: user_id
        });
    }, [getList, job_id, user_id, job_history_id])
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resData, resSuccess]);
    const groupedData = item?.soft_skill.reduce((acc, curr) => {
        const existingItem = acc.find(elm => elm.skill_id === curr.skill_id);

        if (existingItem) {
            if (curr.user_id <= 0 && curr.job_id > 0) {
                existingItem.job_id = curr.job_id;
                existingItem.hm_score = curr.score;
            } else if (curr.user_id > 0) {
                existingItem.user_id = curr.user_id;
                existingItem.score = curr.score;
            }
        } else {
            const newItem = { ...curr };
            if (curr.job_id > 0) {
                newItem.score = 0;
                newItem.hm_score = curr.score;
            } else {
                newItem.score = curr.score;
                newItem.hm_score = 0;
            }
            acc.push(newItem);
        }
        return acc;
    }, []).reduce((result, elm) => {
        const domainName = elm.domain_name;
        const existingDomain = result.find(item => item.domainName === domainName);
        if (existingDomain) {
            const facetName = elm.facet_name;
            const existingFacet = existingDomain.results.find(item => item.facetName === facetName);

            if (existingFacet) {
                existingFacet.hm_score += elm.hm_score;
                existingFacet.score += elm.score;
            } else {
                existingDomain.results.push({
                    facetName,
                    hm_score: elm.hm_score,
                    score: elm.score
                });
            }
        } else {
            result.push({
                domainName,
                results: [{
                    facetName: elm.facet_name,
                    hm_score: elm.hm_score,
                    score: elm.score
                }]
            });
        }
        return result;
    }, []);
    const getDomainBasedMatchedFacets = (i) => {
        const matchedFacets = groupedData[i]?.results?.filter((data) => Math.abs(data?.hm_score - data?.score) <= 2);
        return matchedFacets?.length;
    };
    const getTotalMatchedFacets = () => {
        return groupedData?.map((elm, i) => (elm?.results?.filter((data) => Math.abs(data?.hm_score - data?.score) <= 2))).flat()?.length;
    }
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <JsJobInfo>
                <HmJobSearchTabs />
                <div className="card-body px-0 pb-0">
                    <Alert variant="dark" className='payment-card mb-3'>
                        <Alert.Heading className='d-flex justify-content-between mb-3 h6'>
                            <p className='mb-0'>job {currentHmJob?.job_id || "-"} - {currentHmJob?.job_details?.custom_role ? currentHmJob?.job_details?.custom_role : currentHmJob?.job_details?.role || "-"}</p>
                        </Alert.Heading>
                        <div className='row'>
                            {/* <div className="col-lg-12">
                                <div className='row align-items-center mb-3'>
                                    <div className="col-lg-3">job id / </div>
                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">1111</span></div>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className='row align-items-center mb-3'>
                                    <div className="col-lg-3">hiring manager's name</div>
                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{currentHmJob?.hm_info?.first_name || "-"} {currentHmJob?.hm_info?.last_name}</span></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className='row align-items-center mb-3'>
                                    <div className="col-lg-3">hiring manager's work email</div>
                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{currentHmJob?.hm_info?.email || "-"}</span> <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" onClick={() => handleCopyItem(currentHmJob?.hm_info?.email)} /></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className='row align-items-center mb-3'>
                                    <div className="col-lg-3">hiring goal</div>
                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{currentHmJob?.job_details?.hiring_goal || "-"}</span></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className='row align-items-center mb-3'>
                                    <div className="col-lg-3">soft skill score</div>
                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{`${item?.soft_skill_score || "0"}%`}</span></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className='row align-items-center'>
                                    <div className="col-lg-3">total facets matched</div>
                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{getTotalMatchedFacets()}</span></div>
                                </div>
                            </div>
                        </div>
                    </Alert>
                    {/*  */}
                    <div className="card mb-0">
                        <div className="card-header">
                            <h5 className="card-title">matched soft skills</h5>
                            <div className="action_btns">
                                <Link to={`/user/job-search/match/soft-skill/detail/${job_uuid}${location?.search}`} className='btn btn-primary'>see details soft skills test</Link>
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            {groupedData?.map((elm, i) => (
                                <div className="card mb-3" key={i}>
                                    <div className="card-header">
                                        <h5 className="card-title">Domain:&nbsp;{elm?.domainName || "-"}</h5>
                                        <div className="action_btns">
                                            facets matched <span className="badge rounded-pill bg-info ms-2">{getDomainBasedMatchedFacets(i)}</span>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="table-responsive">
                                            <div className="inner" />
                                            <table className="table table-striped no_wrap">
                                                <thead>
                                                    <tr>
                                                        <th>Facet Name</th>
                                                        <th>hiring manager's score</th>
                                                        <th>job seeker's score</th>
                                                        <th>match result</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {elm?.results?.map((result, i) => (
                                                        <tr key={i}>
                                                            <td data-label="Facet Name">{result?.facetName || "-"}</td>
                                                            <td data-label="hiring manager's score">{result?.hm_score || "-"}</td>
                                                            <td data-label="job seeker's score">{result?.score || "-"}</td>
                                                            <td data-label="match result">{result?.hm_score === result?.score ? "match" : Math.abs(result?.hm_score - result?.score) <= 2 ? "matched" : "not matched" || "-"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </JsJobInfo>
        </AdminLayout>
    );
}

export default HmSoftSkillMatch;