import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar(props) {
    const location = useLocation();
    const [lookupsDropdown, setLookupsDropdown] = useState(false);
    const [softSkillsDropdown, setSoftSkillsDropdown] = useState(false);
    const [adminDropdown, setAdminDropdown] = useState(false);
    useEffect(() => {
        if (location?.pathname?.includes('lookups')) {
            setLookupsDropdown(true);
        } else if (location?.pathname?.includes('soft-skills-quiz')) {
            setSoftSkillsDropdown(true);
        } else if (location?.pathname?.includes('admin')) {
            setAdminDropdown(true);
        }
    }, [location])
    return (
        <div className="navbar p-0">
            <div className="desk_logo">
                <Link to="/dashboard" className="text-center">
                    <img src="/assets/images/logo-3.png" className="image-fit-contain" alt="logo" />
                </Link>
            </div>
            <nav className="navigation w-100">
                <ul className="main_nav">
                    <li className="menu-item">
                        <Link to="/dashboard" className={location?.pathname?.includes('dashboard') ? "active" : ""}>Dashboard</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/users" className={location?.pathname?.includes('user') ? "active" : ""} >User management</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/jobs" className={location?.pathname?.includes('jobs') ? "active" : ""} >Jobs management</Link>
                    </li>
                    <li className={`menu-item has-submenu ${location?.pathname?.includes('soft-skills-quiz') ? "active" : ""}`} >
                        <Link to="#" className={(location?.pathname?.includes('soft-skills-quiz') || softSkillsDropdown) ? "active" : ""} onClick={() => setSoftSkillsDropdown(!softSkillsDropdown)}>Manage Skill Quiz</Link>
                        <ul className={`sub-menu ${softSkillsDropdown ? "show" : ""}`}>
                            <li className='menu-item'>
                                <Link to="/soft-skills-quiz/list" className={location?.pathname?.includes('soft-skills-quiz/list') ? "active" : ""}>soft skills</Link>
                            </li>
                            <li className='menu-item'>
                                <Link to="/soft-skills-quiz/score" className={location?.pathname?.includes('/soft-skills-quiz/score') ? "active" : ""}>scoring key</Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`menu-item has-submenu ${location?.pathname?.includes('lookups') ? "active" : ""}`} >
                        <Link to="#" className={(location?.pathname?.includes('lookups') || lookupsDropdown) ? "active" : ""} onClick={() => setLookupsDropdown(!lookupsDropdown)}>Manage Lookups</Link>
                        <ul className={`sub-menu ${lookupsDropdown ? "show" : ""}`}>
                            <li className="menu-item">
                                <Link to="/lookups/hiring" className={location?.pathname?.includes('/lookups/hiring') ? "active" : ""}>Hiring Questions</Link>
                            </li>
                            <li className='menu-item'>
                                <Link to="/lookups/industry" className={(location?.pathname?.includes('lookups/industry') || location?.pathname?.includes('lookups/department') || location?.pathname?.includes('lookups/role')) ? "active" : ""}>Industry Listing</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/lookups/interview-questions" className={location?.pathname?.includes('lookups/interview-questions') ? "active" : ""}> Post Interview Questions </Link>
                            </li>
                            {/* <li className="menu-item">
                                <Link to="/lookups/match-criteria" className={location?.pathname?.includes('lookups/match-criteria') ? "active" : ""}>Match Criteria</Link>
                            </li> */}
                        </ul>
                    </li>
                    <li className="menu-item">
                        <Link to="/reported" className={location?.pathname?.includes('reported') ? "active" : ""}>Reported Users</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/invoice" className={location?.pathname?.includes('invoice') ? "active" : ""}>Invoice Management</Link>
                    </li>
                    <li className={`menu-item has-submenu ${location?.pathname?.includes('admin') ? "active" : ""}`} >
                        <Link to="#" className={(location?.pathname?.includes('admin') || adminDropdown) ? "active" : ""} onClick={() => setAdminDropdown(!adminDropdown)}>Administrative Panel</Link>
                        <ul className={`sub-menu ${adminDropdown ? "show" : ""}`}>
                            <li className="menu-item">
                                <Link to="/admin/roles" className={location?.pathname?.includes('admin/role') ? "active" : ""}>roles</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/list" className={location?.pathname?.includes('admin/list') ? "active" : ""}>admin users</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item">
                        <Link to="/pricing/company-info" className={location?.pathname?.includes('pricing') ? "active" : ""}>Pricing</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/soft-skills-listing" className={location?.pathname?.includes('soft-skills-listing') ? "active" : ""}>soft skills answer</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/export" className={location?.pathname?.includes('export') ? "active" : ""}>export requests</Link>
                    </li>
                </ul>
            </nav >
        </div >
    );
}

export default Navbar;