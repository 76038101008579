import React from 'react';
import { handleCopyItem } from '../../helper';

function ViewInput({ title, value, verified, isCopy }) {
    return (
        <>
            <label className='text-white' htmlFor={title?.replaceAll(" ", "")}>{title}</label>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    value={value || "-"}
                    id={title?.replaceAll(" ", "")}
                    disabled
                />
                {verified && (<i className="bi bi-patch-check-fill verified text-success" />)}
                {isCopy && (<i className="bi bi-clipboard-fill isCopy text-white" onClick={() => handleCopyItem(value)} />)}

            </div>
        </>
    );
}

export default ViewInput;