import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import JsJobInfo from '../components/js-job-info';
import JsJobSearchTabs from '../components/js-job-search-tabs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCandidateJobDetailMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import { setCurrentJsJob } from '../../../../rtk/feature/commonSlice';
import { intToBool } from '../../../../helper';

function JsJobSearchDetail(props) {
    const dispatch = useDispatch();
    const { search_id } = useParams();
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateJobDetailMutation();
    useEffect(() => {
        getList({ job_history_id: search_id, });
    }, [getList, search_id]);
    useEffect(() => {
        if (resSuccess) {
            dispatch(setCurrentJsJob(resData?.data?.[0]));
            setItem(resData?.data?.[0]);
        }
    }, [dispatch, resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <JsJobInfo>
                <JsJobSearchTabs />
                {/*  */}
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Job history details</h5>
                    </div>
                    <div className="card-body details_style">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>most recent employer</label>
                                    <p className="mb-0">{item?.recent_employer || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>experienced</label>
                                    <p className="mb-0">{intToBool(item?.is_experienced) ? "yes" : "no" || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>company type</label>
                                    <p className="mb-0">{item?.company_type?.company_type || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>company size</label>
                                    <p className="mb-0">{item?.company_type?.company_size || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>industry</label>
                                    <p className="mb-0">{item?.industry_info?.industry || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>department</label>
                                    <p className="mb-0">{item?.department_info?.department_title || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group no-detail-style mb-0">
                                    <label>seniority level</label>
                                    <p className="mb-0">{item?.level?.seniority_level || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group no-detail-style mb-0">
                                    <label>role</label>
                                    <p className="mb-0">{item?.custom_role ? item?.custom_role : item?.role_id?.title || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group no-detail-style mb-0">
                                    <label>responsibility</label>
                                    <p className="mb-0">{item?.level?.responsibility_level || "-"} {item?.level?.sub_responsibility_title && (<span>{'> ' + item?.level?.sub_responsibility_title}</span>)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title">relevant experience details</h5>
                    </div>
                    <div className="card-body details_style">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-group">
                                    <label>software languages</label>
                                    <p className="mb-0 comma-list">
                                        {item?.software_info?.length > 0 ? item?.software_info?.map((elm, i) => (<span key={i}>{elm?.software_detail_info?.title || "-"}</span>)) : "none"}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>role experience</label>
                                    <p className="mb-0">{item?.relevant_experience?.role_experience || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>total work experience</label>
                                    <p className="mb-0">{item?.relevant_experience?.total_work_experience || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>education level</label>
                                    <p className="mb-0">{item?.relevant_experience?.education_level || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>patents</label>
                                    <p className="mb-0">{intToBool(item?.is_patents) ? "yes" : "no"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>certificates in place</label>
                                    <p className="mb-0">{intToBool(item?.is_published_research) ? "yes" : "no"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>veteran</label>
                                    <p className="mb-0">{intToBool(item?.is_veteran) ? "yes" : "no"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group no-detail-style mb-0">
                                    <label>security clearance</label>
                                    <p className="mb-0">{intToBool(item?.is_security_clearance) ? "yes" : "no"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
            </JsJobInfo>
        </AdminLayout>
    );
}

export default JsJobSearchDetail;