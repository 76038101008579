import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserTabs from '../components/userTabs';
import UserInfo from '../components/userInfo';
import { useUserRefinePostedMutation } from '../../../../rtk/services/userApi';
import { Link, useParams } from 'react-router-dom';
import { constants } from '../../../../constants';
import { useState } from 'react';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import CommonPagination from '../../../../components/shared/CommonPagination';
import { dateFormat, intToBool, priceFormat } from '../../../../helper';

function JsJobPosts(props) {
    const { userId } = useParams();
    const pageLimit = constants.common.limit - 1;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [totalCount, setTotalCount] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserRefinePostedMutation();
    useEffect(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            js_uuid: userId,
        });
    }, [getList, userId, pageLimit, pageOffset]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title col-lg-4">User Job Searches</h5>
                </div>
                <div className="card-body pb-0">
                    <UserTabs />
                    <div className="row">
                        {itemList?.length > 0 ? itemList?.map((job, i) => (
                            <div className="col-lg-4" key={i}>
                                {/* job card */}
                                <div className="card job-card">
                                    <div className="card-header">
                                        <h6 className="card-title fs-16">search id : {job?.job_details?.job_history_id || "-"}</h6>
                                    </div>
                                    <div className="card-body text-center">
                                        <h5 className='title mb-4'>{job?.job_search_title || "-"}</h5>
                                        <p className='text-white mb-3'>{job?.job_details?.job_type || "-"} | {job?.industry_info?.industry || "-"} | {(job?.job_details?.total_compensation && (priceFormat(job?.job_details?.total_compensation) + "/" + job?.job_details?.salary_pay_periods)) || "-"}</p>
                                        <p className='text-white mb-3'>
                                            <i className="fa-solid fa-location-dot me-2" />
                                            {job?.location_info?.slice(0, 1)?.map((elm, i) => (<>
                                                {intToBool(elm.is_country) && (<span key={i}>{elm?.country}</span>)}
                                                {intToBool(elm.is_state) && (<span key={i}>{elm?.state}, {elm?.country}</span>)}
                                                {intToBool(elm.is_city) && (<span key={i}>{elm?.city}, {elm?.state}, {elm?.country}</span>)}
                                            </>)) || '-'}
                                        </p>
                                        <p className='text-white mb-2'>Posted On : {dateFormat(job?.created_at, 'DD MMM YYYY')}</p>
                                    </div>
                                    <div className="card-footer">
                                        <Link to={`/user/job-search/details/${job?.job_details?.job_history_id}/${userId}`} className='btn btn-primary btn-sm fs-14 px-3 py-2 w-100'>view details</Link>
                                    </div>
                                    <div className="inner" />
                                </div>
                                {/* job card */}
                            </div>
                        )) : <div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>}
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
        </AdminLayout>
    );
}

export default JsJobPosts;