import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { generateMonthsArray } from '../../../../helper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    plugins: {
        title: {
            display: true,
            position: 'top',
            align: 'start',
            color: "#000000",
            font: {
                size: 18,
                weight: 700
            }
        },
        legend: {
            display: true,
            position: 'bottom',
        }
    },
    maintainAspectRatio: false,
    scales: {
        x: {
            title: {
                display: true,
                font: {
                    size: 18,
                    weight: 700
                }
            },
            stacked: true,
        },
        y: {
            title: {
                display: false,
                font: {
                    size: 18,
                    weight: 700
                }
            },
            stacked: true,
        },
    },
};

function ChartOne({ title, data }) {
    const dataChart = {
        labels: generateMonthsArray(),
        datasets: [{
            label: "onboard hiring manager",
            backgroundColor: "#22567E",
            borderColor: "#1d3557",
            borderRadius: 5,
            data: [30, 100, 20, 30, 40, 80, 50, 60, 80, 90, 45, 100],
        },
        {
            label: "onboard job seeker",
            backgroundColor: "#0abab5",
            borderColor: "#1d3557",
            borderRadius: 5,
            data: [90, 0, 20, 40, 40, 90, 20, 46, 70, 36, 45, 70],
        },
        {
            label: "total",
            backgroundColor: "#2BABFF",
            borderColor: "#1d3557",
            borderRadius: 5,
            data: [90, 0, 20, 40, 40, 90, 20, 46, 70, 36, 45, 70],
        }
        ]
    };
    return (
        <div className="card has_col">
            <div className="card-header">
                <h5 className="card-title">{title}</h5>
                <div className="action_btns">
                    <button type="button" className="btn btn-primary"> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
                </div>
            </div>
            <div className="card-body">
                <Bar options={options} data={dataChart} className="w-100 h-100" />
            </div>
        </div>
    );
}

export default ChartOne;