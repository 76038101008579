import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import { useSelector } from 'react-redux';
import UserTabs from '../components/userTabs';
import { companySizes, companyTypes } from '../../../../helper';
import ViewInput from '../../../../components/helper/ViewInput';

function UserCompanyDetails(props) {
    const { currentUser } = useSelector(({ common }) => common);
    const companyInfo = currentUser?.user_info?.[0]?.company_details
    return (
        <AdminLayout>
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">User Company Detail</h5>
                </div>
                <div className="card-body pb-0">
                    <UserTabs />
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title="Company" value={companyInfo?.company_name} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title={"Type"} value={companyTypes(companyInfo?.company_type)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title={"Size"} value={companySizes(companyInfo?.company_size)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title={"Industry"} value={companyInfo?.company_industry} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title={"my company location"} value={companyInfo?.company_hq_zip_code} />
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default UserCompanyDetails;