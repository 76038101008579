import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardData: null,
    currentUser: null,
    currentHmJob: null,
    currentJsJob: null,
    currentHmMatch: null,
    currentJsMatch: null,
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setDashboardData: (state, action) => {
            state.dashboard = action.payload;
        },
        clearDashboardData: (state) => {
            state.dashboard = null;
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        clearCurrentUser: (state) => {
            state.currentUser = null;
        },
        setCurrentHmJob: (state, action) => {
            state.currentHmJob = action.payload;
        },
        clearCurrentHmJob: (state) => {
            state.currentHmJob = null;
        },
        setCurrentHmMatch: (state, action) => {
            state.currentHmMatch = action.payload;
        },
        clearCurrentHmMatch: (state) => {
            state.currentHmMatch = null;
        },

        setCurrentJsJob: (state, action) => {
            state.currentJsJob = action.payload;
        },
        clearCurrentJsJob: (state) => {
            state.currentJsJob = null;
        },

        setCurrentJsMatch: (state, action) => {
            state.currentJsMatch = action.payload;
        },
        clearCurrentJsMatch: (state) => {
            state.currentJsMatch = null;
        },
        clearAll: (state) => {
            state.dashboardData = null;
            state.currentUser = null;
            state.currentHmJob = null;
            state.currentJsJob = null;
            state.currentHmMatch = null;
            state.currentJsMatch = null
        }
    }
});

export const {
    setDashboardData,
    clearDashboardData,
    setCurrentUser,
    clearCurrentUser,
    setCurrentHmJob,
    clearCurrentHmJob,
    setCurrentHmMatch,
    clearCurrentHmMatch,
    setCurrentJsJob,
    clearCurrentJsJob,
    setCurrentJsMatch,
    clearCurrentJsMatch,
    clearAll,
} = commonSlice.actions;

export default commonSlice.reducer;
