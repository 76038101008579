import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import Input from '../../../components/helper/Input';
import BackButton from '../../../components/helper/backButton';
import { useGetRoleListMutation, useUpdateUserPermissionMutation } from '../../../rtk/services/adminUserApi';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader';


const initialValues = {
    roleName: "",
    permissions: {},
};


const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("role name is required. please enter your role name."),
    permissions: Yup.object()
});

function AddEditRole(props) {
    const navigate = useNavigate();
    const { id, roleName } = useParams();
    const [name, setName] = useState(initialValues);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetRoleListMutation();
    const [updateUserPermissionMutation, { data: updateData, isSuccess: updateSuccess, isLoading: updateLoading, error: updateError }] = useUpdateUserPermissionMutation();
    const [permissions, setPermissions] = useState([]);
    const columnMap = {
        edit: 'Edit',
        read: 'View',
        create: 'Add',
        delete: 'Delete',
    };
    const columns = ['View', 'Add', 'Edit', 'Delete'];

    useEffect(() => {
        getList({ role_id: parseInt(id) })
    }, [id]);
    useEffect(() => {
        if (resSuccess && roleName) {
            let obj = {
                roleName: roleName,
            }
            setName(obj);
            const groupedData = resData?.data?.reduce((per, item) => {
                const { module_id, module_name, privilege_name, user_role_privilege_id, is_active, is_available } = item;

                if (!per[module_id]) {
                    per[module_id] = {
                        id: module_id,
                        title: module_name,
                        permissionType: []
                    };
                }

                per[module_id].permissionType.push({
                    id: user_role_privilege_id,
                    type: privilege_name.toLowerCase(),
                    show: Boolean(is_available),
                    checked: Boolean(is_active)
                });

                return per;
            }, {});

            setPermissions(Object.values(groupedData));
        }
    }, [resSuccess && roleName])

    const handleSubmit = async (values) => {
        const { roleName, permissions } = values;

        const activePrivilegeIds = Object.values(permissions)
            .flatMap(privileges => Object.values(privileges))
            .filter(privilege => privilege?.id && privilege.checked)
            .map(privilege => privilege.id);

        const inactivePrivilegeIds = Object.values(permissions)
            .flatMap(privileges => Object.values(privileges))
            .filter(privilege => privilege?.id && !privilege.checked)
            .map(privilege => privilege.id);

        const payload = {
            active_user_role_privilege_ids: activePrivilegeIds,
            in_active_user_role_privilege_ids: inactivePrivilegeIds,
            role_id: parseInt(id),
            role_name: roleName,
        };
        console.log(payload, "payload");
        await updateUserPermissionMutation(payload);
    };
    useEffect(() => {
        if (updateSuccess) {
            navigate("/admin/roles")
        }
    }, [updateSuccess])

    const organizedData = permissions?.map((item) => {
        const organizedPermissionType = columns.map((column) => {
            const type = Object.keys(columnMap).find(
                (key) => columnMap[key] === column
            );
            const permission = item?.permissionType.find(
                (perm) => perm.type.toLowerCase() === type
            );
            return permission || { show: false, checked: false };
        });

        return {
            title: item?.title || "-",
            permissionType: organizedPermissionType,
        };
    });

    return (
        <AdminLayout>
            {(resLoading || updateLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={name}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                    setValues,
                    setFieldValue
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">edit role</h5>
                                    <div className="action_btns">
                                        <BackButton />
                                        <button type="submit" className="btn btn-primary"> <i className="bi bi-save" /> save</button>
                                    </div>
                                </div>
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="roleName" className='ms-2 text-white'>role name</label>
                                            <Input type="text" name="roleName" placeholder="role name" />
                                        </div>
                                    </div>
                                    {/* <hr className='my-0' /> */}
                                    <div className="card-header">
                                        <h5 className="card-title">permission</h5>
                                    </div>
                                    <hr className='my-0 border-top-0' />
                                    <div className="card-header d-block text-center">
                                        <div className="row">
                                            <div className="col-lg-4" />
                                            <div className="col-lg-2">
                                                <h6 className='my-3'>
                                                    View
                                                </h6>
                                            </div>
                                            <div className="col-lg-2">
                                                <h6 className='my-3'>
                                                    Add
                                                </h6>
                                            </div>
                                            <div className="col-lg-2">
                                                <h6 className='my-3'>
                                                    Edit
                                                </h6>
                                            </div>
                                            <div className="col-lg-2">
                                                <h6 className='my-3'>
                                                    Delete
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body py-0 text-center">
                                        {organizedData?.map((item, i) => (
                                            <div className="row my-3 align-items-center" key={i}>
                                                <div className="col-lg-4">
                                                    <h6 className='mb-0 text-start'>
                                                        {item?.title || "-"}
                                                    </h6>
                                                </div>
                                                {item?.permissionType?.map((elm, j) => (
                                                    <div className="col-lg-2" key={j}>
                                                        {elm?.show ? <div className="custom_checkbox">
                                                            <label htmlFor={elm?.type + "-" + elm?.id}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={elm?.type + "-" + elm?.id}
                                                                    name="permissions"
                                                                    checked={elm?.checked}
                                                                    onChange={(e) => {
                                                                        const { checked } = e.target;
                                                                        const updatedList = organizedData?.map((perm, k) => {
                                                                            if (k === i) {
                                                                                return {
                                                                                    ...perm,
                                                                                    permissionType: perm?.permissionType?.map((role, l) => {
                                                                                        if (l === j) {
                                                                                            return {
                                                                                                ...role,
                                                                                                checked: !role?.checked
                                                                                            };
                                                                                        } else {
                                                                                            return role;
                                                                                        }
                                                                                    })
                                                                                };
                                                                            } else {
                                                                                return perm;
                                                                            }
                                                                        });
                                                                        setFieldValue(
                                                                            `permissions.${item?.title}.${elm?.type}`,
                                                                            {
                                                                                id: elm?.id,
                                                                                checked: checked,
                                                                            }
                                                                        );
                                                                        setPermissions(updatedList)
                                                                        console.log(updatedList, "updatedList")
                                                                    }}
                                                                />
                                                                <span />
                                                            </label>
                                                        </div> : <p className='mb-0 text-white'>N/A</p>}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                    {/* <hr className='mt-0' />
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='text-group'>
                                                <label htmlFor="status" className='ms-2'>status</label>
                                                <div className="row">
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="active">
                                                                <Field type="radio" id="active" className="form-check-input me-2" name="status" value={"active"} />
                                                                <span>active</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="inActive">
                                                                <Field type="radio" id="inActive" className="form-check-input me-2" name="status" value={"inactive"} />
                                                                <span>in-active</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="status" component="div" className='invalid-feedback d-block' />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </AdminLayout>
    );
}

export default AddEditRole;