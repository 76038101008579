import React from 'react';
import AdminLayout from './adminLayout';
import PricingTabs from '../../pages/admin/pricing/pricingTabs';

function PricingLayout({ children }) {
    return (
        <AdminLayout>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Pricing</h5>
                </div>
                <div className="card-body">
                    <PricingTabs />
                    {children}
                </div>
            </div>
        </AdminLayout>
    );
}

export default PricingLayout;