import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { customSwalFire, getStatusType, intToBool, userTypeFunction } from '../../../../helper';
import { useDeleteUserMutation, useGetUserDetailsMutation, useUpdateUserStatusMutation, useUserSwitchAccountMutation } from '../../../../rtk/services/userApi';
import { constants } from '../../../../constants';
import Loader from '../../../../components/loader';
import { setCurrentUser } from '../../../../rtk/feature/commonSlice';

function UserInfo(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector(({ common }) => common);
    const [switchUser, switchResponse] = useUserSwitchAccountMutation();
    const [updateItem, updateItemResponse] = useUpdateUserStatusMutation();
    const [deleteItem, deleteItemResponse] = useDeleteUserMutation();
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useGetUserDetailsMutation();
    const handleSwitch = () => {
        let title = "Switch Account"
        let textMessage = "Are you sure you want to switch account?"
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                switchUser({ uuid: currentUser?.uuid });
            }
        });
    }
    const handleActions = (type) => {
        let title = "";
        let textMessage = "";
        if (type === constants?.actionTypes?.blockUnblock) {
            if (!currentUser.is_active) {
                title = "Unblock?"
                textMessage = "Are you sure you want to Unblock?"
            } else {
                title = "Block?"
                textMessage = "Are you sure you want to Block?"
            }
        } else if (type === constants?.actionTypes?.delete) {
            title = "Delete?"
            textMessage = "Are you sure you want to Delete?"
        }
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                if (type === constants?.actionTypes?.blockUnblock) {
                    updateItem({ user_uuid: currentUser?.uuid, is_active: currentUser.is_active === 1 ? 0 : 1 })
                } else if (type === constants?.actionTypes?.delete) {
                    deleteItem({ user_uuid: currentUser?.uuid })
                }
            }
        });
    }
    useEffect(() => {
        if (switchResponse.isSuccess) {
            navigate(`/user/detail/${currentUser?.uuid}`);
            window.location.reload();
            switchResponse.reset();
        }
    }, [navigate, currentUser, switchResponse.isSuccess, switchResponse]);
    useEffect(() => {
        if (updateItemResponse.isSuccess || deleteItemResponse.isSuccess) {
            if (updateItemResponse.isSuccess) {
                dispatch(setCurrentUser({
                    ...currentUser,
                    is_active: currentUser?.is_active === 1 ? 0 : 1
                }));
            }
            if (deleteItemResponse.isSuccess) {
                navigate("/users")
            }
            updateItemResponse.reset();
            deleteItemResponse.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteItemResponse, navigate, updateItemResponse]);
    useEffect(() => {
        if (location?.search?.includes("userUuid")) {
            getList({ user_uuid: location?.search?.split("=")?.[1] });
        }
    }, [location, getList]);
    useEffect(() => {
        if (resSuccess) {
            dispatch(setCurrentUser(resData?.data?.[0]));
        }
    }, [dispatch, resData, resSuccess]);
    return (
        <div className="card">
            {(switchResponse.isLoading || updateItemResponse.isLoading || resFetching || deleteItemResponse.isLoading) && (<Loader />)}
            <div className="card-header">
                <button type="button" className="btn btn-primary ms-0" onClick={() => navigate(-1)}> <i className="bi bi-arrow-left" /> Back</button>
                <div className="action_btns">
                    <button type="button" className="btn btn-primary" onClick={() => handleActions(constants?.actionTypes?.blockUnblock)}> <i className={`fas fa-${intToBool(currentUser?.is_active) ? "unlock" : "lock"}`} /> {getStatusType(currentUser?.is_active)}</button>
                    <button type="button" className="btn btn-primary" onClick={() => handleActions(constants?.actionTypes?.delete)}> <i className='bi-trash' /> Delete</button>
                    {intToBool(currentUser?.already_swapped) && (<button type="button" className="btn btn-primary" onClick={() => handleSwitch()}> <i className="bi bi-arrow-left-right" /> Switch Account</button>)}
                </div>
            </div>
            <div className="card-body">
                <Alert variant="dark" className='mb-0'>
                    <Alert.Heading>{currentUser && (`${currentUser?.first_name || '-'} ${currentUser?.last_name} profile Details`)} </Alert.Heading>
                    <p className='mb-0'>
                        <span className="badge rounded-pill bg-dark me-2"><i className="bi-star text-warning me-2" />{userTypeFunction(currentUser?.user_type_Id) ? currentUser?.hm_reliability_rating : currentUser?.js_reliability_rating}</span>
                        <span className={`badge rounded-pill me-2 ${intToBool(currentUser?.is_active) ? "bg-success" : "bg-danger"}`} >{getStatusType(currentUser?.is_active)}</span>
                        <span className="badge rounded-pill bg-dark">
                            {currentUser?.user_type_Id === constants.userTypeId.js ? 'Job Seeker' : currentUser?.user_type_Id === constants.userTypeId.hm ? 'Hiring Manager' : 'Both'}
                        </span>
                    </p>
                </Alert>
            </div>
        </div>
    );
}

export default UserInfo;