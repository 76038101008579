import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import HmJobTabs from '../components/hm-job-tabs';
import { Link, useParams } from 'react-router-dom';
import { constants } from '../../../../constants';
import { useUserMatchesListMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import CommonPagination from '../../../../components/shared/CommonPagination';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import { handleCopyItem, intToBool } from '../../../../helper';
import { useDispatch } from 'react-redux';
import { setCurrentHmMatch } from '../../../../rtk/feature/commonSlice';
import moment from 'moment';

function HmJobMatches(props) {
    const { job_uuid } = useParams();
    const dispatch = useDispatch();
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [totalCount, setTotalCount] = useState(0);
    const [hmStatus, setHmStatus] = useState(0);
    const [jsStatus, setJsStatus] = useState(0);
    const [interviewStatus, setInterviewStatus] = useState("");
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserMatchesListMutation();
    useEffect(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            job_uuid: job_uuid,
            hm_action: hmStatus,
            js_action: jsStatus,
            // interview_action: interviewStatus,
        });
    }, [getList, job_uuid, hmStatus, pageLimit, pageOffset, jsStatus, interviewStatus]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data?.map((elm, i) => ({
                ...elm,
                job_uuid: job_uuid
            })));
            setTotalCount(resData?.total_records)
        }
    }, [resData, resSuccess, job_uuid]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <HmJobTabs />
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title">matches ({totalCount})</h5>
                    </div>
                    <div className="card-body pb-0">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                    <select className="form-select" value={hmStatus} onChange={(e) => { setHmStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusHmAction.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div> 
                            <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                    <select className="form-select" value={jsStatus} onChange={(e) => { setJsStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusJsAction.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                    <select className="form-select" value={interviewStatus} onChange={(e) => { setInterviewStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusInterview.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body py-0">
                        <div className="table-responsive">
                            <div className="inner" />
                            <table className="table table-striped no_wrap">
                                <thead>
                                    <tr>
                                        <th>Sr no.</th>
                                        <th>Candidate id</th>
                                        <th>JS's Action</th>
                                        <th>HM's Action</th>
                                        <th>Interview status</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemList?.length > 0 ? itemList?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="Sr no.">{(pageOffset) * pageLimit + i + 1}</td>
                                                <td data-label="Candidate id">{item?.user_details?.candidate_first_name || "-"} {item?.user_details?.candidate_last_name} {item?.user_details?.user_unique_name || "-"} <i className="bi bi-clipboard-fill isCopy ms-2 cursor-pointer" onClick={() => handleCopyItem(item?.user_details?.user_unique_name)} /> </td>
                                                <td data-label="JS's Action">{
                                                    item?.is_js_rejected == 1 ? "rejected" : item?.is_js_rejected == 0 ? "accepted" : ""
                                                }</td>
                                                <td data-label="HM's Action">{
                                                    item?.is_hm_accepted == 1 ? "accepted" : item?.is_hm_rejected == 0 ? "rejected" : (item?.is_hm_accepted == 0 && item?.is_hm_rejected == 0) ? "" : ""
                                                }</td>
                                                <td data-label="Interview status">{
                                                    (item?.is_cancelled == 0 && moment.utc(item?.interview_date).local().isSameOrBefore()) ? "completed" :
                                                        intToBool(item?.is_cancelled) ? "cancelled"
                                                            : (item?.is_cancelled == 0 && moment.utc(item?.interview_date).local().isSameOrAfter()) ? "pending"
                                                                : (intToBool(item?.is_hm_accepted)) ? "no interview"
                                                                    : ""}</td>
                                                <td data-label="action">
                                                    <div className="action_wrap">
                                                        <Link to={"/user/job/match/detail/" + item?.user_details?.job_history_id} className="btn btn-icon" onClick={() => dispatch(setCurrentHmMatch(item))}>
                                                            <i className="bi-eye" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                            :
                                            <NoRecordsFound />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer">
                        <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default HmJobMatches;