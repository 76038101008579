import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { generateMonthsArray } from '../../../../helper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    plugins: {
        title: {
            display: true,
            position: 'top',
            align: 'start',
            color: "#000000",
            font: {
                size: 18,
                weight: 700
            }
        },
        legend: {
            display: true,
            position: 'bottom',
        }
    },
    maintainAspectRatio: false,

    scales: {
        x: {
            title: {
                display: true,
                font: {
                    size: 18,
                    weight: 700
                }
            },
            stacked: false,
        },
        y: {
            title: {
                display: false,
                font: {
                    size: 18,
                    weight: 700
                }
            },
            stacked: false,
        },
    },
};

function ChartTwo({ title, data }) {
    const dataChart = {
        labels: generateMonthsArray(),
        datasets: [
            {
                label: "hm accepted",
                backgroundColor: "#22567E",
                borderColor: "#1d3557",
                borderRadius: 5,
                data: [10, 60, 20, 30, 80, 30, 90, 60, 100],
            },
            {
                label: "job searches made",
                backgroundColor: "#0abab5",
                borderRadius: 5,
                data: [30, 100, 20, 30, 40, 80, 50, 60, 80, 90, 45, 100],
            },
            {
                label: "job matches made",
                backgroundColor: "#19A7CE",
                borderRadius: 5,
                data: [20, 100, 20, 30, 40, 80, 50, 60, 80, 90, 45, 100],
            },
            {
                label: "js accepted",
                backgroundColor: "#FFD93D",
                borderRadius: 5,
                data: [40, 100, 20, 30, 40, 80, 50, 60, 80, 90, 45, 100],
            },
        ]

    };
    return (
        <div className="card has_col">
            <div className="card-header">
                <h5 className="card-title">{title}</h5>
                <div className="action_btns">
                    <button type="button" className="btn btn-primary"> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
                </div>
            </div>
            <div className="card-body">
                <Bar options={options} data={dataChart} className="w-100 h-100" />
            </div>
        </div>
    );
}

export default ChartTwo;