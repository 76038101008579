import React from 'react';

function NoRecordsFound({ loading }) {
    return (
        <tr>
            <td colSpan={"12"} className="text-center">{loading ? "Loading..." : "No record found!"}</td>
        </tr >
    );
}

export default NoRecordsFound;