import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import { useSelector } from 'react-redux';
import { useCandidateInterviewDetailMutation } from '../../../../rtk/services/userApi';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/loader';
import UserInfo from '../components/userInfo';
import JsJobInfo from '../components/js-job-info';
import HmJobSearchTabs from '../components/hm-job-search-tabs';
import { Alert } from 'react-bootstrap';
import { handleCopyItem, intToBool } from '../../../../helper';
import moment from 'moment';

function HmJsJobInterviewDetails(props) {
    const { job_uuid } = useParams();
    const { currentJsMatch } = useSelector(({ common }) => common);

    const [item, setItem] = useState(null);
    const [interview, setInterview] = useState(null);
    const [rescheduleInterview, setRescheduleInterview] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateInterviewDetailMutation();
    useEffect(() => {
        getList({
            candidate_uuid: currentJsMatch?.user_details?.user_uuid,
            job_uuid: job_uuid,
        });
    }, [getList, currentJsMatch, job_uuid]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.interview?.[0]);
            setInterview(resData?.interview?.[0]);
            setRescheduleInterview(resData?.rescheduled_interview);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <JsJobInfo>
                <HmJobSearchTabs />
                <div className="card-body px-0 pb-0">
                    {item && (
                        <Alert variant="dark" className='payment-card mb-3'>
                            <Alert.Heading className='d-flex justify-content-between mb-3 h6'>
                                <p className='mb-0'>job {item?.job_id || "-"} - {item?.job_details?.custom_role ? item?.job_details?.custom_role : item?.job_details?.role || "-"}</p>
                            </Alert.Heading>
                            <div className='row'>
                                <div className="col-lg-12">
                                    <div className='row align-items-center mb-3'>
                                        <div className="col-lg-3">hiring manager's name</div>
                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{item?.hm_info?.first_name || "-"} {item?.hm_info?.last_name}</span></div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className='row align-items-center'>
                                        <div className="col-lg-3">hiring manager's work email</div>
                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{item?.hm_info?.work_email || "-"}</span> <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" onClick={() => handleCopyItem(item?.hm_info?.work_email)} /></div>
                                    </div>
                                </div>
                            </div>
                        </Alert>
                    )}
                    <div className="card mb-0">
                        <div className="card-header">
                            <h5 className="card-title">Interview feedback</h5>
                        </div>
                        <div className="card-body pb-0">
                            {/*  */}
                            {interview && (
                                <Alert variant="dark" className='payment-card mb-3'>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">current interview status</div>
                                                <div className="col-lg-auto">
                                                    {interview?.user_info?.is_cancel && (<span className="badge rounded-pill bg-danger ms-2">cancelled by {interview?.user_info?.cancelled_by === 1 ? "hiring manager" : interview?.user_info?.cancelled_by === 0 ? "job seeker" : ""} </span>)}
                                                    {intToBool(interview?.is_accepted) && (<span className="badge rounded-pill bg-success ms-2">{!moment.utc(interview?.interview_date).local().add(30, "minutes").isSameOrAfter(moment(), "seconds") ? "completed" : "pending"}</span>)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">date</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(interview?.interview_date).local().format("DD/MM/YYYY")}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">day</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment(interview?.interview_date).format("dddd")}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='row align-items-center'>
                                                <div className="col-lg-3">time</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(interview?.interview_date).local().format("HH:mm")}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </Alert>
                            )}
                            {/*  */}
                            {rescheduleInterview?.length > 0 && (
                                <>
                                    <hr />
                                    <div className="card-header pt-0 pb-3 mb-3">
                                        <h5 className="card-title">previous scheduled details</h5>
                                    </div>
                                    {/*  */}
                                    {rescheduleInterview?.map((elm, i) => (
                                        <Alert variant="dark" className='payment-card mb-3' key={i}>
                                            <div className='row'>
                                                <div className="col-lg-12">
                                                    <div className='row align-items-center mb-3'>
                                                        <div className="col-lg-3">interview status</div>
                                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">rescheduled by {elm?.rescheduled_by === 1 ? "hiring manager" : elm?.rescheduled_by === 0 ? "job seeker" : ""}</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className='row align-items-center mb-3'>
                                                        <div className="col-lg-3">date</div>
                                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(elm?.created_at).local().format("DD/MM/YYYY")}</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className='row align-items-center mb-3'>
                                                        <div className="col-lg-3">day</div>
                                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment(elm?.created_at).format("dddd")}</span></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className='row align-items-center'>
                                                        <div className="col-lg-3">time</div>
                                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(elm?.created_at).local().format("HH:mm")}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Alert>
                                    ))}
                                </>
                            )}
                            {!interview && (<div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div>)}
                            {/*  */}
                        </div>
                    </div>

                </div>
            </JsJobInfo>
        </AdminLayout>
    );
}

export default HmJsJobInterviewDetails;