import CryptoJS from 'crypto-js';
import moment from 'moment';
import Swal from 'sweetalert2'
import { constants } from './constants';
import countries from "./countries.json";
import { toast } from 'react-toastify';
import { clearAuth, sign_Out } from './rtk/feature/authSlice';
import { clearAll } from './rtk/feature/commonSlice';

export const numberFormatStyle = (data) => {
    if (data) {
        const number = Intl.NumberFormat('en', {
            notation: 'compact'
        });
        return number.format(data);
    } else {
        return 0
    }

};

export const encryptKeys = (objectData) => {
    const jsonString = JSON.stringify(objectData);
    const encrypted = CryptoJS.AES.encrypt(jsonString, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_KEY), {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_IVKEY),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const encryptedString = encrypted.toString();
    return encryptedString;
}

export const decryptKeys = (encryptedText) => {
    if (encryptedText != null && encryptedText !== undefined) {
        const decrypted = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_KEY), {
            iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_IVKEY),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
        const decryptedObject = JSON.parse(decryptedString);
        return decryptedObject;
    } else {
        return encryptedText;
    }
}
export const encrypt = (decryptedText) => {
    const decrypted = CryptoJS.AES.encrypt(decryptedText, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_KEY), {
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_IVKEY),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const data = decrypted.toString();
    return data;
}
export const decrypt = (encryptedText) => {
    if (encryptedText != null && encryptedText !== undefined) {
        const decrypted = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_KEY), {
            iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_IVKEY),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const data = decrypted.toString(CryptoJS.enc.Utf8)
        return data;
    } else {
        return encryptedText;
    }
}
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const textTitleCase = (text) => {
    if (text) {
        // debugger
        let words = text.split(' ');
        // let words = text;
        for (let i = 0; i < words.length; ++i) {
            words[i] = capitalizeFirstLetter(words[i]);
        }
        return words.join(' ');
    } else {
        return '-'
    }
}
export const downloadFile = (data, filename, columns) => {
    let csvData = ConvertToCSV(data, columns);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let downloadLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
        navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
        downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export const ConvertToCSV = (objArray, headerList) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
        row += textTitleCase(headerList[index] + ',');
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = i + 1 + '';
        for (let index in headerList) {
            let head = headerList[index];
            line += ',' + array[i][head];
        }
        str += line + '\r\n';
    }
    return str;
}
export const priceFormat = (number) => {
    if (number) {
        const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(number);
        return USDollar;
    }
    return 0
}
export const phoneNumberAutoFormat = (phoneNumber) => {
    if (phoneNumber) {
        return phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

};
export const dateFormat = (date, format) => {
    if (date) {
        return moment.utc(date).format(format);
    } else {
        return '-'
    }
}
export const companyTypes = (type) => {
    if (type) {
        switch (type) {
            case 1:
                return constants.COMPANY_TYPE.START_UP;
            case 2:
                return constants.COMPANY_TYPE.PUBLIC;
            case 3:
                return constants.COMPANY_TYPE.PRIVATE;
            case 4:
                return constants.COMPANY_TYPE.NO_PREFER;
            default:
                return '';
        }
    } else {
        return '';
    }
};

export const companySizes = (size) => {
    if (size) {
        switch (size) {
            case 1:
                return constants.COMPANY_SIZE.SMALL;
            case 2:
                return constants.COMPANY_SIZE.MEDIUM;
            case 3:
                return constants.COMPANY_SIZE.LARGE;
            case 4:
                return constants.COMPANY_SIZE.BIG;
            case 5:
                return constants.COMPANY_SIZE.BIGGER;
            case 6:
                return constants.COMPANY_SIZE.BIGGEST;
            default:
                return '';
        }
    } else {
        return '';
    }
};

export const getUserType = (typeId) => {
    if (typeId === constants.userTypeId.hm) {
        return constants.userTypesSort.hm;
    } else if (typeId === constants.userTypeId.js) {
        return constants.userTypesSort.js;
    } else if (typeId === constants.userTypeId.admin) {
        return constants.userTypesSort.both;
    } else if (typeId === constants.userTypeId.guest) {
        return constants.userTypesSort.guest;
    }
}
export const getDecodedCountryCode = (code) => {
    const country = countries?.find((elm) => elm?.country_id === parseInt(code));
    return country ? `+${country?.country_code}` : "-";
};

export const getPhoneNumber = (typeId, key) => {
    if (typeId === constants.userTypeId.hm) {
        return phoneNumberAutoFormat(key?.mobile_number);
    } else if (typeId === constants.userTypeId.js) {
        return phoneNumberAutoFormat(key?.personal_number);
    } else {
        return phoneNumberAutoFormat(key?.mobile_number);
    }
}
export const getEmailType = (typeId, key) => {
    if (typeId === constants.userTypeId.hm) {
        return key?.work_email;
    } else if (typeId === constants.userTypeId.js) {
        return key?.personal_email;
    } else {
        return key?.work_email;
    }
}
export const getStatusType = (type) => {
    if (type === 1) {
        return constants.statusTypeText.active
    } else if (type === 0) {
        return constants.statusTypeText.block
    }
}
export const userTypeFunction = (typeId) => {
    if (typeId === constants.userTypeId.hm) {
        return true;
    } else if (typeId === constants.userTypeId.js) {
        return false;
    }
}
export const intToBool = (type) => {
    if (type === 1) {
        return true;
    } else if (type === 0) {
        return false;
    }
}
export const roundFigureData = (minValue, maxValue) => {
    let min = 0, max = 0;
    if (minValue && maxValue) {
        min += (minValue * 100 / 100);
        max += (maxValue * 100 / 100);
    }
    return priceFormat((min + max) / 2);
}
export const getSeniorityType = (type) => {
    return type === 1 ? "medical" : "industry"
}
export const generateMonthsArray = () => {
    const monthsArray = [];

    // Start from the current month
    let currentMonth = moment.utc();

    for (let i = 0; i < 12; i++) {
        // currentMonth.endOf('month');
        // Format the date as "DD/MM/YYYY" and push it to the array
        monthsArray.push(currentMonth.startOf('month').local().format('DD/MM/YYYY'));

        // Move to the next month
        currentMonth.subtract(1, 'month');
    }

    return monthsArray.reverse();
}
export const customSwalFire = (title, text, icon) => {
    return new Promise((resolve, reject) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "button outer-login"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            icon: icon?.includes("assets") ? "" : icon,
            iconHtml: icon?.includes("assets") ? `<img src="${icon}" alt='icon' width='40' filter='invert(1)' />` : "",
            title: title,
            text: text,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: "Confirm",
            padding: "0 0 2.5em 0",
            color: "#ffffff",
            background: "#fff url(/assets/images/background.png)",
            reverseButtons: true,
        }).then((result) => {
            resolve(result);
        });
    });
};

export const handleCopyItem = (value) => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);

    // Select and copy the text using the Clipboard API
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    toast.success(`Copied! ${value}`, {
        position: toast.POSITION.TOP_RIGHT,
    });
}
export const logoutHelper = (dispatch, navigate) => {
    dispatch(clearAuth());
    dispatch(sign_Out());
    dispatch(clearAll());
    navigate('/');
}
export const getModifiedSelectOptions = ({ trueState, data, value, title }) => {
    if (trueState) {
        return data?.map((item) => ({
            value: item?.[value],
            title: item?.[title]
        }));
    }
    return [];
}