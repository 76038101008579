import React, { useCallback, useEffect, useState } from 'react';
import PricingLayout from '../../../components/layouts/pricingLayout';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import ContextAwareToggle from '../../../components/helper/custom-accordion';
import { useGetPriceListMutation } from '../../../rtk/services/priceApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { priceFormat } from '../../../helper';
import CommonPagination from '../../../components/shared/CommonPagination';
import { constants } from '../../../constants';
import AddEditPricingModal from '../../../components/modals/addEditPricingModal';

function IdealCandidate(props) {
    const [itemList, setItemList] = useState([]);
    const [addEditModal, setAddEditModal] = useState(false);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const pageLimit = constants.common.limit;
    const [totalCount, setTotalCount] = useState(0);
    const [currentType, setCurrentType] = useState(constants.priceCategories.INDUSTRY);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetPriceListMutation();
    const getItemList = useCallback(() => {
        getList({
            page_offset: pageOffset + 1,
            page_limit: pageLimit,
            list_type: constants.priceTabs.candidate,
            price_category: currentType
        });
    }, [getList, currentType, pageLimit, pageOffset]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.data?.[0]?.total_count);
        }
    }, [resData, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <PricingLayout>
            {resLoading && (<Loader />)}
            <div className="card mb-0">
                <div className="card-header">
                    <h5 className="card-title">hm job ideal candidate details</h5>
                </div>
                <div className="card-body">
                    <Accordion defaultActiveKey={0}>
                        {/* industry */}
                        <Card key={0}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.INDUSTRY} eventKey={0}>industry</ContextAwareToggle>
                            <Accordion.Collapse eventKey={0}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>industry</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="industry">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.INDUSTRY && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* type */}
                        <Card key={1}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.TYPE} eventKey={1}>company type</ContextAwareToggle>
                            <Accordion.Collapse eventKey={1}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>company type</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="company type">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.TYPE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* size */}
                        <Card key={2}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.SIZE} eventKey={2}>company size</ContextAwareToggle>
                            <Accordion.Collapse eventKey={2}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>company size</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="company size">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.SIZE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* department */}
                        <Card key={3}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.DEPARTMENT} eventKey={3}>department</ContextAwareToggle>
                            <Accordion.Collapse eventKey={3}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>department</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="department">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.DEPARTMENT && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* seniority level */}
                        <Card key={4}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.SENIORITY} eventKey={4}>seniority level</ContextAwareToggle>
                            <Accordion.Collapse eventKey={4}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>seniority level</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="seniority level">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.SENIORITY && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* responsibility */}
                        <Card key={5}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.RESPONSIBILITY} eventKey={5}>responsibility</ContextAwareToggle>
                            <Accordion.Collapse eventKey={5}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>responsibility</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="responsibility">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.RESPONSIBILITY && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* role experience */}
                        <Card key={6}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.ROLE_EXPERIENCE} eventKey={6}>role experience</ContextAwareToggle>
                            <Accordion.Collapse eventKey={6}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>role experience</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="role experience">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.ROLE_EXPERIENCE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* total work experience */}
                        <Card key={7}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.WORK_EXPERIENCE} eventKey={7}>total work experience</ContextAwareToggle>
                            <Accordion.Collapse eventKey={7}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>total work experience</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="total work experience">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.WORK_EXPERIENCE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* education level */}
                        <Card key={8}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.EDUCATION_LEVEL} eventKey={8}>education level</ContextAwareToggle>
                            <Accordion.Collapse eventKey={8}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>education level</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="education level">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.EDUCATION_LEVEL && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* patents */}
                        <Card key={9}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.PATENTS} eventKey={9}>patents</ContextAwareToggle>
                            <Accordion.Collapse eventKey={9}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>patents</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="patents">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.PATENTS && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* certificates in place */}
                        <Card key={10}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.CERTIFICATES} eventKey={10}>certificates in place</ContextAwareToggle>
                            <Accordion.Collapse eventKey={10}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>certificates in place</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="certificates in place">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.CERTIFICATES && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* veteran */}
                        <Card key={11}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.VETERAN} eventKey={11}>veteran</ContextAwareToggle>
                            <Accordion.Collapse eventKey={11}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>veteran</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="veteran">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.VETERAN && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* security clearance */}
                        <Card key={12}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.SECURITY_CLEARANCE} eventKey={12}>security clearance</ContextAwareToggle>
                            <Accordion.Collapse eventKey={12}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>security clearance</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="security clearance">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.SECURITY_CLEARANCE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                    </Accordion>
                </div>
            </div>
            {addEditModal && (<AddEditPricingModal state={addEditModal}
                action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`hm ideal candidate - ${currentType?.replaceAll(/_|ideal|candidate/g, " ")}`} type={currentType} currentItem={currentItem} updateList={() => getItemList()} />)}
        </PricingLayout>
    );
}

export default IdealCandidate;