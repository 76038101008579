import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const skillsApi = createApi({
    reducerPath: "skillsApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getSkillsQuestion: builder.mutation({
            query: (data) => {
                const url = `page_limit=${data?.page_limit}&page_offset=${data?.page_offset}&domain_name=${data?.domain_name || ""}`
                return {
                    url: `/admin/soft-skill?${url}`
                }
            },
        }),
        getSkillsDomainList: builder.mutation({
            query: (data) => {
                const url = `domain_id=${data?.domain_id || ""}&list_type=domain`
                return {
                    url: `/admin/soft-skill/list?${url}`
                }
            },
        }),
        getSkillsFacetList: builder.mutation({
            query: (data) => {
                const url = `domain_id=${data?.domain_id || ""}&list_type=facet`
                return {
                    url: `/admin/soft-skill/list?${url}`
                }
            },
        }),
        getSkillsScoreKey: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/soft-skill/scoring-key`
                }
            },
        }),
        addSoftSkills: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/soft-skill/`,
                    method: "post",
                    headers: {},
                    body,
                }
            },
        }),
        updateSoftSkills: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/soft-skill/`,
                    method: "post",
                    headers: {},
                    body,
                }
            },
        }),
    }),
});

export const {
    useGetSkillsQuestionMutation,
    useGetSkillsDomainListMutation,
    useGetSkillsFacetListMutation,
    useGetSkillsScoreKeyMutation,
    useAddSoftSkillsMutation,
    useUpdateSoftSkillsMutation
} = skillsApi;