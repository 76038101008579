import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from "yup";
import { constants } from '../../../constants';
import Loader from '../../../components/loader';
import { useChangePasswordMutation } from '../../../rtk/services/authApi';
import { logoutHelper } from '../../../helper';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("please enter your password.").matches(constants.regex.passwordRegex, "please ensure your password meets our password policy."),
    newPassword: Yup.string().required("new please enter your password.").matches(constants.regex.passwordRegex, "please ensure your password meets our password policy."),
    confirmPassword: Yup.string().required("confirm please enter your password.").oneOf([Yup.ref("newPassword"), null], "password mismatch. please make sure the passwords match and try again.")
});
const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
}

function Profile(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(({ auth }) => auth);
    const [toggleOldPassword, setToggleOldPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [updatePassword, updatePasswordResponse] = useChangePasswordMutation();
    const handleSubmit = async (values) => {
        await updatePassword({
            current_password: values?.oldPassword,
            new_password: values?.newPassword,
        });
    }
    useEffect(() => {
        if (updatePasswordResponse.isSuccess) {
            logoutHelper(dispatch, navigate);
        }
    }, [dispatch, navigate, updatePasswordResponse.isSuccess])

    return (
        <AdminLayout>
            {updatePasswordResponse.isLoading && (<Loader />)}
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">profile</h5>
                </div>
                <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-group">
                                <label>email address</label>
                                <p className="mb-0">{user?.work_email || "-"}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-group mb-0">
                                <label>role</label>
                                <p className="mb-0">{constants.USER_TYPE[user?.user_type_Id]}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="card-header pt-0">
                    <h5 className="card-title">change password</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validationSchema}
                            >
                                {({ handleSubmit, values }) => {
                                    return (
                                        <form onSubmit={handleSubmit} className="formbox mt-2">
                                            <div className={`form-group type_password  ${values?.oldPassword?.trim() !== "" ? "has-value" : ""}`}>
                                                {/* Password input */}
                                                <Field className="form-control" type={toggleOldPassword ? 'text' : 'password'} name="oldPassword" placeholder="old Password" />
                                                {/* Toggle password visibility button */}
                                                <button type="button" className="togglePassword transform-center" onClick={() => { setToggleOldPassword(!toggleOldPassword) }}>
                                                    <i className={toggleOldPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                                </button>
                                            </div>
                                            <ErrorMessage name="oldPassword" component="div" className="invalid-feedback d-block mb-3" />
                                            <div className={`form-group type_password  ${values?.newPassword?.trim() !== "" ? "has-value" : ""}`}>
                                                {/* Password input */}
                                                <Field className="form-control" type={toggleNewPassword ? 'text' : 'password'} name="newPassword" placeholder="New Password" />
                                                {/* Toggle password visibility button */}
                                                <button type="button" className="togglePassword transform-center" onClick={() => { setToggleNewPassword(!toggleNewPassword) }}>
                                                    <i className={toggleNewPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                                </button>
                                            </div>
                                            <ErrorMessage name="newPassword" component="div" className="invalid-feedback d-block mb-3" />
                                            <div className={`form-group type_password  ${values?.confirmPassword?.trim() !== "" ? "has-value" : ""}`}>
                                                {/* Password input */}
                                                <Field className="form-control" type={toggleConfirmPassword ? 'text' : 'password'} name="confirmPassword" placeholder="Confirm Password" />
                                                {/* Toggle password visibility button */}
                                                <button type="button" className="togglePassword transform-center" onClick={() => { setToggleConfirmPassword(!toggleConfirmPassword) }}>
                                                    <i className={toggleConfirmPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                                </button>
                                            </div>
                                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback d-block mb-3" />
                                            {/* Sign In button */}
                                            <button type="submit" className="btn btn-primary w-100">
                                                Save
                                            </button>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default Profile;