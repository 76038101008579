import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { Alert } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSoftSkillsUserDetailsMutation } from '../../../rtk/services/softSkillsApi';

function SoftSkillsListingList(props) {
    const navigate = useNavigate();
    const { userId, job_id, js_history_id, user_type } = useParams();
    const [user, setUser] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetSoftSkillsUserDetailsMutation();
    useEffect(() => {
        getList({
            job_id: job_id,
            js_history_id: js_history_id,
            user_id: userId,
            user_type: user_type,
        });
    }, [getList]);
    useEffect(() => {
        if (resSuccess) {
            setUser(resData?.data?.user);
            const groupedData = resData?.data?.soft_skill?.reduce((result, elm) => {
                const domainName = elm.domain_name;
                const facetName = elm.facet_name;
                const existingDomain = result.find(item => item.domainName === domainName);

                if (existingDomain) {
                    const existingFacet = existingDomain.facetNames.find(facet => facet.facetName === facetName);
                    if (existingFacet) {
                        existingFacet.score += elm.score; // Assuming score is a numeric value
                    } else {
                        existingDomain.facetNames.push({ facetName, score: elm.score });
                    }
                } else {
                    result.push({
                        domainName,
                        facetNames: [{ facetName, score: elm.score }]
                    });
                }

                return result;
            }, []);
            setItemList(groupedData);
        }
    }, [resSuccess])
    return (
        <AdminLayout>
            <div className="card">
                <div className="card-header">
                    <button type="button" className="btn btn-primary ms-0" onClick={() => navigate(-1)}>
                        <i className="bi bi-arrow-left" /> Back
                    </button>
                </div>
                <div className="card-body">
                    <div role="alert" className="fade mb-0 alert alert-dark show">
                        <div className="alert-heading h4">Harding Long profile Details </div>
                        <p className="mb-0">
                            <span className="badge rounded-pill bg-dark me-2">
                                <i className="bi-star text-warning me-2" />
                                4.1
                            </span>
                            <span className="badge rounded-pill me-2 bg-success">Active</span>
                            <span className="badge rounded-pill bg-dark">Hiring Manager</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">
                        job 18 - Accessory Sales Person
                        <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" />
                    </h5>
                    <div>
                        <span className="badge rounded-pill ms-2 ">active</span>
                        <span className="badge rounded-pill bg-info ms-2">paid full</span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-body px-0 pb-0 ">
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">soft skills test - self</h5>
                                <div className="action_btns">
                                    <Link
                                        className="btn btn-primary"
                                        to="/soft-skills-listing/detail/1"
                                    >
                                        see details soft skills test
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body pb-0">
                                <Alert variant="dark" className='payment-card'>
                                    <Alert.Heading className='d-flex justify-content-between mb-3 h6'>
                                        <p className='mb-0'>invitee</p>
                                    </Alert.Heading>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">Name</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.first_name || "-"} {user?.last_name || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">Seniority Level</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.seniority_level || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">Email Address</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.work_email || user?.personal_email || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">Job title</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.job_title || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center'>
                                                <div className="col-lg-3">Phone Number</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.mobile_number || user?.personal_number || "-"}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </Alert>
                                {itemList?.map((elm, i) => (
                                    <div className="card mb-3" key={i}>
                                        <div className="card-header">
                                            <h5 className="card-title">Domain:&nbsp;{elm?.domainName || "-"}</h5>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="table-responsive">
                                                <div className="inner" />
                                                <table className="table table-striped no_wrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Facet Name</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {elm?.facetNames?.map((facet, i) => (
                                                            <tr key={i}>
                                                                <td data-label="Facet Name">{facet?.facetName || "-"}</td>
                                                                <td data-label="Score">{facet?.card_score || "-"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {itemList?.length === 0 && (<div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default SoftSkillsListingList;