import React, { useEffect, useState } from 'react';
import AuthLayout from '../../components/layouts/authLayout';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from "yup";
import { constants } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../../rtk/services/authApi';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';

const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required(constants?.validations?.requiredPassword)
        .matches(
            constants?.regex?.password,
            constants?.validations?.passwordValidate
        ),
    confirmPassword: Yup.string()
        .required(constants?.validations?.requiredPassword)
        .oneOf([Yup.ref('newPassword'), null], constants?.validations?.sameValidation),
});
const initialValues = {
    newPassword: "",
    confirmPassword: "",
}

function ResetPassword(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [resetUser, res] = useResetPasswordMutation();
    useEffect(() => {
        if (!location?.search?.includes('token')) {
            navigate('/');
        }
    }, [location?.search, navigate])
    const handleSubmit = async (values) => {
        await resetUser({
            token: location?.search.split('&')[1].replace('token=', ''),
            token_value: location?.search.split('&')[0].replace('?tokenValue=', ''),
            new_password: values.confirmPassword
        })
    }
    useEffect(() => {
        if (res.isSuccess) {
            toast.success(res?.data?.message);
            navigate('/');
        }
    }, [res.isSuccess, res?.data, navigate]);
    return (
        <AuthLayout>
            {res.isLoading && (<Loader />)}
            <div className="form_info">
                <h5 className="title mb-2 text-white">Reset Password</h5>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="formbox mt-4">
                                <div className={`form-group type_password  ${values?.newPassword?.trim() !== "" ? "has-value" : ""}`}>
                                    {/* Password input */}
                                    <Field className="form-control" type={toggleNewPassword ? 'text' : 'password'} name="newPassword" placeholder="New Password" />
                                    {/* Toggle password visibility button */}
                                    <button type="button" className="togglePassword transform-center" onClick={() => { setToggleNewPassword(!toggleNewPassword) }}>
                                        <i className={toggleNewPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                    </button>
                                </div>
                                <ErrorMessage name="newPassword" component="div" className="invalid-feedback d-block mb-3" />
                                <div className={`form-group type_password  ${values?.confirmPassword?.trim() !== "" ? "has-value" : ""}`}>
                                    {/* Password input */}
                                    <Field className="form-control" type={toggleConfirmPassword ? 'text' : 'password'} name="confirmPassword" placeholder="Confirm Password" />
                                    {/* Toggle password visibility button */}
                                    <button type="button" className="togglePassword transform-center" onClick={() => { setToggleConfirmPassword(!toggleConfirmPassword) }}>
                                        <i className={toggleConfirmPassword ? 'bi-eye-slash' : 'bi-eye'} />
                                    </button>
                                </div>
                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback d-block mb-3" />
                                {/* Sign In button */}
                                <button type="submit" className="btn btn-primary w-100">
                                    Save
                                </button>
                            </form>
                        );
                    }}
                </Formik>
            </div>

        </AuthLayout>
    );
}

export default ResetPassword;