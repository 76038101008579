import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useJobSoftSkillMutation } from '../../../../rtk/services/userApi';
import { useEffect } from 'react';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import Loader from '../../../../components/loader';

function JsJobSoftSkillDetails(props) {
    const [itemList, setItemList] = useState([]);
    const { currentHmMatch } = useSelector(({ common }) => common);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useJobSoftSkillMutation();
    useEffect(() => {
        getList({
            user_uuid: currentHmMatch?.user_details?.user_uuid
        });
    }, [currentHmMatch?.user_details?.user_uuid, getList])
    useEffect(() => {
        if (resSuccess) {
            const data = resData?.data?.soft_skill?.[0]?.data;
            const mergedData = Object.values(data?.reduce((result, elm) => {
                const { card_number, ...rest } = elm;

                if (!result[card_number]) {
                    // If card_number doesn't exist, create a new entry
                    result[card_number] = { ...rest, cardTwo: {} };
                }

                // Add facet_name to the facet_names object
                result[card_number].cardTwo = elm;

                return result;
            }, {}));
            setItemList(mergedData);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title d-flex align-items-center">
                            candidate {currentHmMatch?.user_details?.user_unique_name || "-"} - {currentHmMatch?.user_details?.candidate_first_name || "-"} {currentHmMatch?.user_details?.candidate_last_name}
                            <span className="badge rounded-pill bg-dark ms-2"><i className="bi-star text-warning me-2" />4.1</span>
                        </h5>
                    </div>
                    <div className="card-body pb-0">
                        <div className="table-responsive">
                            <div className="inner" />
                            <table className="table table-striped no_wrap">
                                <thead>
                                    <tr>
                                        <th>card number</th>
                                        <th>most like me</th>
                                        <th>domain name</th>
                                        <th>facet name</th>
                                        <th>score</th>
                                        <th>empty state</th>
                                        <th>domain name</th>
                                        <th>facet name</th>
                                        <th>score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="card number">{item?.cardTwo?.card_number}</td>
                                            <td data-label="most like me">{item.text}</td>
                                            <td data-label="domain name">{item.domain_name}</td>
                                            <td data-label="facet name">{item.facet_name}</td>
                                            <td data-label="score">{item.score}</td>
                                            <td data-label="empty state">{item.cardTwo.text}</td>
                                            <td data-label="domain name">{item.cardTwo.domain_name}</td>
                                            <td data-label="facet name">{item.cardTwo.facet_name}</td>
                                            <td data-label="score">{item.cardTwo.score}</td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout >
    );
}

export default JsJobSoftSkillDetails;