import React, { useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import UserInfo from './components/userInfo';
import { useGetUserDetailsMutation } from '../../../rtk/services/userApi';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../../../rtk/feature/commonSlice';
import UserTabs from './components/userTabs';
import { getDecodedCountryCode, getEmailType, getPhoneNumber, textTitleCase, userTypeFunction } from '../../../helper';
import ViewInput from '../../../components/helper/ViewInput';

function UserDetails(props) {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const { currentUser } = useSelector(({ common }) => common);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useGetUserDetailsMutation();
    useEffect(() => {
        getList({ user_uuid: userId });
    }, [userId, getList]);
    useEffect(() => {
        if (resSuccess) {
            dispatch(setCurrentUser(resData?.data?.[0]));
        }
    }, [dispatch, resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">User Detail</h5>
                </div>
                <div className="card-body pb-0">
                    <UserTabs />
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title="Name" value={textTitleCase(currentUser?.first_name + ' ' + currentUser?.last_name)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title={userTypeFunction(currentUser?.user_type_Id) ? "Mobile Number" : "Personal Number"} value={getDecodedCountryCode(currentUser?.country_code) + " " + (getPhoneNumber(currentUser?.user_type_Id, currentUser))} verified={true} isCopy={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <ViewInput title={userTypeFunction(currentUser?.user_type_Id) ? "Work Email" : "Personal Email"} value={getEmailType(currentUser?.user_type_Id, currentUser)} isCopy={true} />
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default UserDetails;