import React, { useCallback, useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../constants';
import CommonPagination from '../../../components/shared/CommonPagination';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useGetReportListMutation } from '../../../rtk/services/reportApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { intToBool } from '../../../helper';
import moment from 'moment';

function ReportedList(props) {
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [reportType, setReportType] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetReportListMutation();
    const getItemList = useCallback(() => {
        getList({
            reported_status: reportType,
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
        });
    }, [reportType, pageLimit, pageOffset, getList, searchValue]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <AdminLayout>
            {resLoading && (<Loader />)}
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">reported users ({totalCount})</h5>
                    <div className="action_btns">
                        <button type="button" className="btn btn-primary"> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <select className="form-control form-select" value={reportType} onChange={(e) => { setReportType(e.target.value); setPageOffset(0) }}>
                                    {constants.statusTypeReport?.map((item, i) => (
                                        <option key={i} value={item?.value}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <input type="text" className='form-control' placeholder='Search by name, created by' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body py-0">
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>reported id
                                        <div className="sort ms-2">
                                            <button type="button" className='button'> <i className='bi-sort-numeric-down' /> </button>
                                            <button type="button" className='button'> <i className='bi-sort-numeric-up-alt' /> </button>
                                        </div>
                                    </th>
                                    <th>reported user
                                        <div className="sort ms-2">
                                            <button type="button" className='button'> <i className='bi-sort-alpha-down' /> </button>
                                            <button type="button" className='button'> <i className='bi-sort-alpha-up-alt' /> </button>
                                        </div>
                                    </th>
                                    <th>reporter
                                        <div className="sort ms-2">
                                            <button type="button" className='button'> <i className='bi-sort-down' /> </button>
                                            <button type="button" className='button'> <i className='bi-sort-up-alt' /> </button>
                                        </div>
                                    </th>
                                    <th>reported on</th>
                                    <th>status</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="reported id"><Link to={`/reported/detail/${item?.id}`} className='link-color'>{item?.id || "-"}</Link></td>
                                            <td data-label="reported user"><Link to={`/user/detail/${item?.reported_info?.reported_user_uuid}`} className='link-color'>{item?.reported_info?.reported_user_name || "-"}</Link></td>
                                            <td data-label="reporter"><Link to={`/user/detail/${item?.reported_info?.reporter_user_uuid}`} className='link-color'>{item?.reported_info?.reporter_username || "-"}</Link></td>
                                            <td data-label="reported on">{moment.utc(item?.created_at).local().format("MM/DD/YYYY hh:ss a") || "-"}</td>
                                            <td data-label="status">{intToBool(item?.is_resolved) ? "resolved" : "open"}</td>
                                            <td data-label="action">
                                                <div className="action_wrap">
                                                    <label className="switch">
                                                        <input type="checkbox" defaultChecked={intToBool(item?.is_resolved)} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
        </AdminLayout>
    );
}

export default ReportedList;