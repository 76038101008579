import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const adminUserApi = createApi({
    reducerPath: "adminUserApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getUserPermission: builder.mutation({
            query: (body) => {
                return {
                    url: `/role-management/roles`
                }
            },
        }),
        getRoleList: builder.mutation({
            query: (body) => {
                return {
                    url: `/role-management/role-privileges/`,
                    method: "post",
                    body
                }
            },
        }),
        updateUserPermission: builder.mutation({
            query: (body) => {
                return {
                    url: `/role-management/privilege-status/`,
                    method: "put",
                    body
                }
            },
        }),
        getAdminUserList: builder.mutation({
            query: (body) => {
                return {
                    url: `/role-management/admin-users?page_limit=${body.page_limit || 10}&page_offset=${body.page_offset || 1}`
                }
            },
        }),
        addAdminUser: builder.mutation({
            query: (body) => {
                return {
                    url: `/role-management/add-sub-admin`,
                    method: "post",
                    body
                }
            },
        }),
        updateAdminUser: builder.mutation({
            query: (body) => {
                return {
                    url: `/role-management/active-admin-user`,
                    method: "patch",
                    body
                }
            },
        }),
    }),
});

export const {
    useGetUserPermissionMutation,
    useGetRoleListMutation,
    useUpdateUserPermissionMutation,
    useGetAdminUserListMutation,
    useAddAdminUserMutation,
    useUpdateAdminUserMutation
} = adminUserApi;
