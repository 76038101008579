import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';

function HiringThisJobIs(props) {
    return (
        <AdminLayout>
            
        </AdminLayout>
    );
}

export default HiringThisJobIs;