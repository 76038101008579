import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sign_Out, updateRefreshTokenAndToken } from "../feature/authSlice";
import { toast } from "react-toastify";
import { clearAll } from "../feature/commonSlice";

const abortController = new AbortController();
const signal = abortController.signal;

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { user } = getState().auth;
    if (user) {
      headers.set('Authorization', user?.access_token);
    } else {
      headers.set('Authorization', "");
    }
    headers.set('client-secret', process.env.REACT_APP_CLIENT_SECRET);
    return headers
  },
  signal
  //credentials: 'include'
});

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    const { user } = api.getState().auth;
    // try to get a new token from refresh token
    const refreshResult = await baseQuery({
      url: '/user/actions/refresh-token',
      method: 'POST',
      body: { refresh_token: user?.refresh_token },
      headers: {
        "client-secret": process.env.REACT_APP_CLIENT_SECRET
      }
    }, api, extraOptions)
    console.log(refreshResult);
    //if get new new token
    if (refreshResult.data) {
      // store the new token
      api.dispatch(updateRefreshTokenAndToken(refreshResult.data))
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(sign_Out());
      api.dispatch(clearAll());
      window.location.href = "/";
    }

  } else if (result.error && result.error.status === 404) {
    api.dispatch(sign_Out());
    api.dispatch(clearAll());
    window.location.href = "/";
  } else if (result.error && result.error.status === 411) {
    api.dispatch(sign_Out());
    api.dispatch(clearAll());
    window.location.href = "/";
  } else if (result.error) {
    let errorShown = false;
    const errorMessage = result?.error?.data?.error?.error || result?.error?.data?.error || result?.error?.data?.message || "something went wrong";
    if (!errorShown) {
      // Show the toast
      toast.error(errorMessage);
      // Set the flag to true to indicate that the toast has been shown
      errorShown = true;
    }
  }
  return result
}