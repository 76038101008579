import React, { useEffect, useCallback, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { constants } from '../../../constants';
import CommonPagination from '../../../components/shared/CommonPagination';
import { useDebounce } from 'use-debounce';
import AddEditAdminUsersModal from '../../../components/modals/addEditAdminUsers';
import { useGetAdminUserListMutation, useGetUserPermissionMutation } from '../../../rtk/services/adminUserApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { getModifiedSelectOptions, getStatusType, phoneNumberAutoFormat } from '../../../helper';

function AdminList(props) {
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [searchKey, setSearchKey] = useState("");
    // const [searchValue] = useDebounce(searchKey, 1000);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [userType, setUserType] = useState("");
    const [addEditModal, setAddEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetAdminUserListMutation();
    const [getRole, { data: roleData, isSuccess: roleSuccess, isLoading: roleLoading }] = useGetUserPermissionMutation();
    useEffect(() => {
        getRole();
    }, [getRole]);
    const getItemList = useCallback(() => {
        getList({
            // user_type_id: userType,
            // search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
        });

    }, [getList, pageLimit, pageOffset]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            const count = resData.data.length;
            setItemList(resData?.data);
            setTotalCount(count);
        }
    }, [resData, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    const updatedUserType = getModifiedSelectOptions({ trueState: roleSuccess, data: roleData?.data, value: "user_role_id", title: "role_name" });
    return (
        <AdminLayout>
            {(resLoading || roleLoading) && (<Loader />)}
            <div className="card has_col">
                <div className="card-header">
                    <h5 className="card-title">admin users ({totalCount})</h5>
                    <div className="action_btns">
                        <button type="button" className="btn btn-primary" onClick={() => { setAddEditModal(true); setCurrentItem(null); }}> <i className="bi bi-plus" /> add</button>
                        <button type="button" className="btn btn-primary"> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <select className="form-control form-select" value={userType} onChange={(e) => { setUserType(e.target.value); setPageOffset(0) }}>
                                    <option value="">Select role</option>
                                    {updatedUserType?.map((item, i) => (
                                        <option key={i} value={item?.value}>{item?.title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <input type="text" className='form-control' placeholder='Search by name, email, mobile no.' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body py-0">
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>name</th>
                                    <th>email address</th>
                                    <th>mobile number</th>
                                    <th>role</th>
                                    <th>status</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemList?.length > 0 ? (
                                    itemList.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="name">{item?.first_name || "-"}</td>
                                            <td data-label="email address">{item?.work_email || "-"}</td>
                                            <td data-label="mobile number">{item?.mobile_number ? phoneNumberAutoFormat(item?.mobile_number) : "-"}</td>
                                            <td data-label="role">{constants.USER_TYPE[item?.admin_role_id] || "-"}</td>
                                            <td data-label="status">{getStatusType(item?.is_active) || "-"}</td>
                                            <td data-label="action">
                                                <div className="action_wrap">
                                                    <button type="button" className="btn btn-icon" onClick={() => { setAddEditModal(true); setCurrentItem(item); }}>
                                                        <i className="bi-pencil" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </tbody>

                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
            {addEditModal && (<AddEditAdminUsersModal state={addEditModal}
                action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} admin user`} currentItem={currentItem} updateList={() => getItemList()} />)}
        </AdminLayout>
    );
}

export default AdminList;