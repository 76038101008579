import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getDashboardInfo: builder.mutation({
            query: (body) => {
                const url = `filter_type=${body?.filter_type}`;
                return {
                    url: `/admin/user/dashboard?${url}`
                }
            },
        }),
    }),
});

export const {
    useGetDashboardInfoMutation,
} = dashboardApi;
