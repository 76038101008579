import React, { useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../constants';
import { useCallback } from 'react';
import CommonPagination from '../../../components/shared/CommonPagination';
import { Link } from 'react-router-dom';
import { useDeletePostInterviewQuestionMutation, useGetPostInterviewQuestionListMutation } from '../../../rtk/services/lookupsApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { customSwalFire, encryptKeys, intToBool } from '../../../helper';

function InterviewQuestions(props) {
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [userType, setUserType] = useState("");
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useGetPostInterviewQuestionListMutation();
    const [deleteItem, deleteItemResponse] = useDeletePostInterviewQuestionMutation();
    const getItemList = useCallback(() => {
        getList({
            user_type: userType,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
        });
    }, [userType, pageLimit, pageOffset, getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    const handleActions = (item, type) => {
        let title = "";
        let textMessage = "";
        if (type === constants?.actionTypes?.delete) {
            title = "Delete?"
            textMessage = "Are you sure you want to Delete?"
        }
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                if (type === constants?.actionTypes?.delete) {
                    let options = [];
                    item?.options?.map((elm, i) => {
                        let optionObj = {
                            is_deleted: 1,
                            option: elm?.option,
                            question_id: elm?.question_id,
                            option_id: elm?.option_id,
                            is_sub_option: elm?.sub_options?.length > 0 ? 1 : 0,
                            sub_options: (elm?.sub_options || []).map((subElm, i) => ({ is_deleted: 1, option_id: subElm?.option_id, sub_option_id: subElm?.sub_option_id, question_id: subElm?.question_id, sub_option_text: subElm?.sub_option_text }))
                        }
                        options.push(optionObj);
                    })
                    let obj = {
                        question_id: item?.question_id,
                        question_type: "PRE_INTERVIEW",
                        user_type: item?.user_type,
                        question: item?.question,
                        is_delete_enabled: item?.is_delete_enabled || 1,
                        options: options,
                        is_deleted: 1
                    }
                    deleteItem(obj)
                }
            }
        });
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    useEffect(() => {
        if (deleteItemResponse.isSuccess) {
            getItemList();
        }
    }, [deleteItemResponse.isSuccess, getItemList]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <AdminLayout>
            {(resFetching || deleteItemResponse.isLoading) && (<Loader />)}
            <div className="card has_col">
                <div className="card-header">
                    <h5 className="card-title">post interview questions ({totalCount})</h5>
                    <div className="action_btns">
                        <Link to={"/lookups/interview-questions/add"} className="btn btn-primary"> <i className="bi bi-plus" /> Add</Link>
                        <button type="button" className="btn btn-primary"> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <select className="form-control form-select" value={userType} onChange={(e) => { setUserType(e.target.value); setPageOffset(0) }}>
                                    {constants.userTypeNormal?.map((item, i) => (
                                        <option key={i} value={item?.value}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body py-0">
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>question text</th>
                                    <th>user type</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="question text">{item?.question || "-"}</td>
                                            <td data-label="user type">{constants.USER_TYPE[item?.user_type] || "-"}</td>
                                            <td data-label="action">
                                                <div className="action_wrap">
                                                    <Link to={"/lookups/interview-questions/edit/" + item?.question_id + "?allText=" + encryptKeys(item)} className="btn btn-icon">
                                                        <i className="bi-pencil" />
                                                    </Link>
                                                    {intToBool(item?.is_delete_enabled) && (
                                                        <button type="button" className="btn btn-icon"
                                                            onClick={(e) => handleActions(item, constants?.actionTypes?.delete)}
                                                        >
                                                            <i className="bi-trash" />
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
        </AdminLayout>
    );
}

export default InterviewQuestions;