import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import JsJobInfo from '../components/js-job-info';
import JsJobSearchTabs from '../components/js-job-search-tabs';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useUserMatchesListMutation } from '../../../../rtk/services/userApi';
import { constants } from '../../../../constants';
import Loader from '../../../../components/loader';
import CommonPagination from '../../../../components/shared/CommonPagination';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import { setCurrentJsMatch } from '../../../../rtk/feature/commonSlice';

function JsJobMatches(props) {
    const { user_uuid } = useParams();
    const dispatch = useDispatch();
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [totalCount, setTotalCount] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [status, setStatus] = useState(0);
    const [interviewStatus, setInterviewStatus] = useState("");
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserMatchesListMutation();
    useEffect(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            js_uuid: user_uuid,
        });
    }, [getList, user_uuid, pageLimit, pageOffset]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records)
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <JsJobInfo>
                <JsJobSearchTabs />
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title">matches ({totalCount})</h5>
                    </div>
                    <div className="card-body pb-0">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                    <select className="form-select" value={status} onChange={(e) => { setStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusAction.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="form-group">
                                    <select className="form-select" value={interviewStatus} onChange={(e) => { setInterviewStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusInterview.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body py-0">
                        <div className="table-responsive">
                            <div className="inner" />
                            <table className="table table-striped no_wrap">
                                <thead>
                                    <tr>
                                        <th>Sr no.</th>
                                        <th>job</th>
                                        <th>status</th>
                                        <th>Interview status</th>
                                        <th>View</th> 
                                    </tr>
                                </thead>
                                <tbody> 
                                    {
                                        itemList?.length > 0 ? itemList?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="Sr no.">{(pageOffset) * pageLimit + i + 1}</td>
                                                <td data-label="job">{item?.job_details?.job_id || "-"} {item?.job_details?.job_title || "-"} </td>
                                                <td data-label="status">00224</td>
                                                <td data-label="Interview status">{item?.interview_status || "-"}</td>
                                                <td data-label="action">
                                                    <div className="action_wrap">
                                                        <Link to={"/user/job-search/match/detail/" + item?.job_details?.job_uuid} className="btn btn-icon" onClick={() => dispatch(setCurrentJsMatch(item))}>
                                                            <i className="bi-eye" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                            :
                                            <NoRecordsFound />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer">
                        <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                    </div>
                </div>
            </JsJobInfo>
        </AdminLayout>
    );
}

export default JsJobMatches;