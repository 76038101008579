import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useAddEditLookupsMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import Input from '../helper/Input';
 
const initialValues = {
    industry_name: "",
    seniority_type: "",
};
const validationSchema = Yup.object().shape({
    industry_name: Yup.string().required("industry name is required. please enter your industry name."),
    seniority_type: Yup.string().required("seniority type is required. please select your seniority type."),
});

function AddEditIndustryModal(props) {
    const [formData, setFormData] = useState(initialValues);
    const [addEditLookUps, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useAddEditLookupsMutation();
    useEffect(() => {
        if (props.currentItem) {
            let obj = {
                industry_name: props.currentItem?.name,
                seniority_type: props.currentItem?.seniority_level === 1 ? "one" : "zero"
            }
            setFormData(obj);
        }
    }, [props.currentItem]);
    const handleSubmit = (values) => {
        let payload = {};
        if (props.currentItem) {
            payload.industry_uuid = props.currentItem?.uuid;
            payload.industry_name = values?.industry_name;
            payload.seniority_type = values?.seniority_type === "one" ? 1 : 0;
        } else {
            payload.industry_uuid = "";
            payload.industry_name = values?.industry_name;
            payload.seniority_type = values?.seniority_type === "one" ? 1 : 0;
        }
        addEditLookUps(payload);
    }
    useEffect(() => {
        if (resSuccess) {
            toast.success(resData?.message);
            props.action();
            props.updateList();
        }
    }, [resSuccess, resError, resData?.message, props])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {resFetching && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input type="text" name="industry_name" placeholder="Industry Name" />
                            <label htmlFor='name'>Seniority Type</label>
                            <div className='text-group'>
                                <div className="row">
                                    <div className="col-lg-auto">
                                        <div className="custom_checkbox">
                                            <label htmlFor="Industrial">
                                                <Field type="radio" id="Industrial" className="form-check-input me-2" name="seniority_type" value={"zero"} />
                                                <span>Industrial</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-auto">
                                        <div className="custom_checkbox">
                                            <label htmlFor="Medical">
                                                <Field type="radio" id="Medical" className="form-check-input me-2" name="seniority_type" value={"one"} />
                                                <span>Medical</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage name="seniority_type" component="div" className='invalid-feedback d-block' />
                            </div>
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddEditIndustryModal;