import React from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';

function SoftSkillsDetail(props) {
    return (
        <AdminLayout>
            <div className="card">
                <div className="card-header">
                    <button type="button" className="btn btn-primary ms-0">
                        <i className="bi bi-arrow-left" /> Back
                    </button>
                </div>
                <div className="card-body">
                    <div role="alert" className="fade mb-0 alert alert-dark show">
                        <div className="alert-heading h4">Harding Long profile Details </div>
                        <p className="mb-0">
                            <span className="badge rounded-pill bg-dark me-2">
                                <i className="bi-star text-warning me-2" />
                                4.1
                            </span>
                            <span className="badge rounded-pill me-2 bg-success">Active</span>
                            <span className="badge rounded-pill bg-dark">Hiring Manager</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">
                        job 18 - Accessory Sales Person
                        <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" />
                    </h5>
                    <div>
                        <span className="badge rounded-pill ms-2 ">active</span>
                        <span className="badge rounded-pill bg-info ms-2">paid full</span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-body px-0 py-0">
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">detailed soft skills test</h5>
                            </div>
                            <div className="card-body pb-0">
                                <div className="table-responsive">
                                    <div className="inner" />
                                    <table className="table table-striped no_wrap">
                                        <thead>
                                            <tr>
                                                <th>card no.</th>
                                                <th>most like me</th>
                                                <th>domain name</th>
                                                <th>facet name</th>
                                                <th>score</th>
                                                <th>empty state</th>
                                                <th>domain name</th>
                                                <th>facet name</th>
                                                <th>score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-label="card no.">1</td>
                                                <td data-label="most like me">keep my promises</td>
                                                <td data-label="domain name">conscientiousness</td>
                                                <td data-label="facet name">Dutifulness</td>
                                                <td data-label="score">1</td>
                                                <td data-label="empty state">have a lot of fun</td>
                                                <td data-label="domain name">Extraversion</td>
                                                <td data-label="facet name">Cheerfulness</td>
                                                <td data-label="score">3</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AdminLayout>
    );
}

export default SoftSkillsDetail;