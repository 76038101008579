import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import { useParams } from 'react-router-dom';
import { useUserFeedbackListMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import { Alert } from 'react-bootstrap';

function HmJobFeedbackDetail(props) {
    const { user_uuid, job_uuid, feedback_type } = useParams();
    const feedback = feedback_type === "PRE-INTERVIEW" ? 1 : feedback_type === "POST-INTERVIEW" ? 2 : "";
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserFeedbackListMutation();
    useEffect(() => {
        getList({
            user_uuid: user_uuid,
            job_uuid: job_uuid,
            feedback_stage: feedback
        });
    }, [getList, user_uuid, job_uuid, feedback]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data?.[0]);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Candidate info</h5>
                </div>
                <div className="card-body">
                    <Alert variant="dark" className='payment-card mb-3'>
                        <Alert.Heading className='d-flex justify-content-between mb-3 h6'><p className='mb-0'>candidate &nbsp;{item?.user_unique_name || "-"} - {item?.candidate_first_name || "-"} {item?.candidate_last_name || ""}</p></Alert.Heading>
                        <div>
                            <div className='row mb-3'>
                                <div className="col-lg-2">job</div>
                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{item?.job_id || "-"} - {item?.job_title || "-"}</span></div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-2">feedback stage</div>
                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{item?.feedback_type || "-"}</span></div>
                            </div>
                        </div>
                    </Alert>
                    {/*  */}
                    {feedback === 1 && (
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">Reason for job rejection</h5>
                            </div>
                            <div className="card-body">
                                <ul className='row'>
                                    {item?.rejection?.map((elm, i) => (
                                        <li key={i} className='col-auto'>
                                            <div className="custom_checkbox">
                                                <label htmlFor={elm?.title?.replaceAll(" ", "")}>
                                                    <input type="checkbox" id={elm?.title?.replaceAll(" ", "")} className="form-check-input me-2" checked />
                                                    <span>{elm?.title || "-"}</span>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {feedback === 2 && (
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">Feedback by hiring manager</h5>
                            </div>
                            <div className="card-body">
                                {/*  */}
                                <div className="row mb-4">
                                    <div className="col-auto">
                                        <div className="custom_checkbox">
                                            <label htmlFor="titleOne" className='pe-0'>
                                                <input type="checkbox" id="titleOne" className="form-check-input me-2" checked />
                                                <span />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <h6 className='text-white mb-2'>How accurate was this match?</h6>
                                        <p className='text-white mb-0'> {item?.hm_accuracy_title || "-"}</p>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="custom_checkbox">
                                            <label htmlFor="titleTwo" className='pe-0'>
                                                <input type="checkbox" id="titleTwo" className="form-check-input me-2" checked />
                                                <span />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <h6 className='text-white mb-2'>Would you like to continue interview?</h6>
                                        <p className='text-white mb-0'> {item?.is_continue === 1 ? "yes" : "no"}</p>
                                    </div>
                                </div>
                                {/*  */}
                            </div>
                        </div>
                    )}
                    {/*  */}
                </div>
            </div>
        </AdminLayout>
    );
}

export default HmJobFeedbackDetail;