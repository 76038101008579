import React from 'react';
import { Modal } from 'react-bootstrap';

function ModalLayout(props) {
    return (
        <Modal
            show={props.state}
            size={props?.size ? props?.size : "md"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            backdrop="static"
            dialogClassName='custom-modal'
            onHide={props.action}
        >
            <Modal.Header>
                <h5 className='text-center text-white mb-0'>{props.title}</h5>
                <button type='button' className="btn-close" aria-label="Close" onClick={props.action}><i className='bi-x-lg' /></button>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    );
}

export default ModalLayout;