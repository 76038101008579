import React from 'react';
import { useSelector } from 'react-redux';
import { handleCopyItem, intToBool } from '../../../../helper';
import { constants } from '../../../../constants';
import moment from 'moment';

function HmJobInfo({ children }) {
    const { currentHmJob } = useSelector(({ common }) => common);
    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">
                    job {currentHmJob?.job_id || "-"} - {currentHmJob?.job_details?.custom_role ? currentHmJob?.job_details?.custom_role : currentHmJob?.job_details?.role || "-"}
                    <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" onClick={() => handleCopyItem(currentHmJob?.job_id)} />
                </h5>
                <div>
                    <span className={`badge rounded-pill ms-2 ${intToBool(currentHmJob?.is_expired) ? "bg-danger" : ""}`}>{intToBool(currentHmJob?.is_expired) ? "expired" : "active"}</span>
                    {!intToBool(currentHmJob?.is_expired) && (<>
                        {intToBool(currentHmJob?.job_details?.job_payment_status) && (<span className='badge rounded-pill bg-info ms-2'>paid full</span>)}
                        {(intToBool(currentHmJob?.job_details?.feedback_payment_status) && !intToBool(currentHmJob?.job_details?.job_payment_status)) && (<span className='badge rounded-pill bg-info ms-2'>paid feedback</span>)}
                        {(!intToBool(currentHmJob?.job_details?.feedback_payment_status) && !intToBool(currentHmJob?.job_details?.job_payment_status) && !moment.utc(currentHmJob?.created_at).local().add(constants.common.trailDays, "days").diff(moment(), "days") <= 0) && (<span className='badge bg-danger rounded-pill ms-3'>unpaid</span>)}
                        {/* {(!intToBool(currentHmJob?.job_details?.feedback_payment_status) && !intToBool(currentHmJob?.job_details?.job_payment_status) && moment.utc(currentHmJob?.created_at).local().add(constants.common.trailDays, "days").diff(moment(), "days") >= 0) && (<span className='badge bg-danger rounded-pill ms-3'>expired</span>)} */}
                    </>)}
                </div>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
}

export default HmJobInfo;