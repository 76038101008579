import React from 'react';
import { useSelector } from 'react-redux';
import Widgets from '../../pages/admin/dashboard/components/widgets';

function SharedWidgets({ title, show }) {
    const { dashboard } = useSelector(({ common }) => common)
    return (
        <div className="card has_col">
            <div className="card-header">
                <h5 className="card-title">{title}</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    {[
                        { title: "Total Users", value: dashboard?.total_users },
                        { title: "Job Seekers", value: dashboard?.total_js },
                        { title: "Hiring Manager", value: dashboard?.total_hm },
                        { title: "Total Job Posted", value: dashboard?.job_posted },
                        { title: "Total Interviews", value: dashboard?.total_interview },
                        { title: "Total Revenue", value: dashboard?.total_revenue },
                        { title: "Pre Interview Rejections", value: dashboard?.total_pre_interview_rejection },
                        { title: "Post Interview Rejections", value: dashboard?.total_post_interview_rejection },
                        { title: "Interview Qualified", value: dashboard?.total_qualified_interview },
                    ].slice(0, show).map((condition, index) => (
                        <Widgets key={index} title={condition.title} value={condition.value} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SharedWidgets;