import React from 'react';
import { useSelector } from 'react-redux';

function JsJobInfo({ children }) {
    const { currentJsJob } = useSelector(({ common }) => common);
    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">
                    search {currentJsJob?.user_job_history_id || "-"} - {currentJsJob?.recent_employer || "-"}
                </h5>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
}

export default JsJobInfo;