import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const priceApi = createApi({
    reducerPath: "priceApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getPriceList: builder.mutation({
            query: (body) => {
                const url = `page_offset=${body?.page_offset || ""}&page_limit=${body?.page_limit || ""}&list_type=${body?.list_type || ""}&price_category=${body?.price_category || ""}`;
                return {
                    url: `/admin/user/job/price-category?${url}`
                }
                // const url = `list_type=${body?.list_type || ""}`;
                // return {
                //     url: `/admin/user/job/price?${url}`
                // }
            },
        }),
        getJobOpeningPriceList: builder.mutation({
            query: (body) => {
                const url = `list_type=${body?.list_type || ""}`;
                return {
                    url: `/admin/user/job/price/job-opening?${url}`
                }
            },
        }),
        updatePrice: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/price-category`,
                    // url: `/admin/user/job/price`,
                    method: "put",
                    body
                }
            },
        }),
    }),
});

export const {
    useGetPriceListMutation,
    useGetJobOpeningPriceListMutation,
    useUpdatePriceMutation
} = priceApi;
