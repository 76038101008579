import React, { useEffect, useState } from "react";
import { getter } from "../rtk/utils/StorageUtil";
import Navigate from "./Navigate";

/**
 *
 * @param {*} children component
 * @returns It will check whether the user has the access token or not,
 * if user has token it will let that user access to the children component
 * else it will redirect that user to the login page. 
 */
const PublicRoute = ({ children }) => {
  const [tkn, setTkn] = useState(false);
  useEffect(() => {
    const token = typeof window !== "undefined" && getter("user");
    if (token) {
      setTkn(true);
    } else {
      setTkn(false);  
    } 
  }, []);
  // return children;
  return tkn ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
