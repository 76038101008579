import React, { useCallback, useEffect, useState } from 'react';
import PricingLayout from '../../../components/layouts/pricingLayout';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import ContextAwareToggle from '../../../components/helper/custom-accordion';
import { useGetPriceListMutation } from '../../../rtk/services/priceApi';
import Loader from '../../../components/loader';
import { constants } from '../../../constants';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { priceFormat } from '../../../helper';
import AddEditPricingModal from '../../../components/modals/addEditPricingModal';
import CommonPagination from '../../../components/shared/CommonPagination';

function JobOpenings(props) {
    const [itemList, setItemList] = useState([]);
    const [addEditModal, setAddEditModal] = useState(false);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const pageLimit = constants.common.limit;
    const [totalCount, setTotalCount] = useState(0);
    const [currentType, setCurrentType] = useState(constants.priceCategories.HIRING_GOAL);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetPriceListMutation();
    const getItemList = useCallback(() => {
        getList({
            page_offset: pageOffset + 1,
            page_limit: pageLimit,
            list_type: constants.priceTabs.jobOpening,
            price_category: currentType
        });
    }, [getList, currentType, pageLimit, pageOffset]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.data?.[0]?.total_count);
        }
    }, [resData, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <PricingLayout>
            {resLoading && (<Loader />)}
            <div className="card mb-0">
                <div className="card-header">
                    <h5 className="card-title">hm job openings details</h5>
                </div>
                <div className="card-body">
                    <Accordion defaultActiveKey={0}>
                        {/* hiring goal */}
                        <Card key={0}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.HIRING_GOAL} eventKey={0}>hiring goal</ContextAwareToggle>
                            <Accordion.Collapse eventKey={0}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>hiring goal</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="hiring goal">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.HIRING_GOAL && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* department */}
                        <Card key={1}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.DEPARTMENT} eventKey={1}>department</ContextAwareToggle>
                            <Accordion.Collapse eventKey={1}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>department</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="department">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.DEPARTMENT && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* seniority level */}
                        <Card key={2}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.SENIORITY} eventKey={2}>seniority level</ContextAwareToggle>
                            <Accordion.Collapse eventKey={2}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>seniority level</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="seniority level">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.SENIORITY && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* responsibility */}
                        <Card key={3}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.RESPONSIBILITY} eventKey={3}>responsibility</ContextAwareToggle>
                            <Accordion.Collapse eventKey={3}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>responsibility</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="responsibility">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.RESPONSIBILITY && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* role */}
                        <Card key={4}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.ROLE} eventKey={4}>role</ContextAwareToggle>
                            <Accordion.Collapse eventKey={4}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>role</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="role">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.ROLE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* software languages */}
                        <Card key={5}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.SOFTWARE} eventKey={5}>software languages</ContextAwareToggle>
                            <Accordion.Collapse eventKey={5}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>software languages</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="software languages">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.SOFTWARE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* location */}
                        <Card key={6}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.LOCATION} eventKey={6}>location</ContextAwareToggle>
                            <Accordion.Collapse eventKey={6}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>location</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="location">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.LOCATION && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* total compensation */}
                        <Card key={7}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.COMPENSATION} eventKey={7}>total compensation</ContextAwareToggle>
                            <Accordion.Collapse eventKey={7}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>total compensation</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="total compensation">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.COMPENSATION && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                        {/* working style */}
                        <Card key={8}>
                            <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.WORKING_STYLE} eventKey={8}>working style</ContextAwareToggle>
                            <Accordion.Collapse eventKey={8}>
                                <Card.Body>
                                    <div className="table-responsive mb-0">
                                        <div className="inner" />
                                        <table className="table table-striped no_wrap">
                                            <thead>
                                                <tr>
                                                    <th>working style</th>
                                                    <th>fee ($)</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList.length > 0 ?
                                                    itemList?.map((elm, i) => (
                                                        <tr key={i}>
                                                            <td data-label="working style">{elm?.price_type_value || "-"}</td>
                                                            <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                            <td data-label="action">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <NoRecordsFound />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            <div className="card-footer">
                                {currentType === constants.priceCategories.WORKING_STYLE && (
                                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                )}
                            </div>
                        </Card>
                    </Accordion>
                </div>
            </div>
            {addEditModal && (<AddEditPricingModal state={addEditModal}
                action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`hm job opening - ${currentType?.replaceAll(/_|ideal|candidate/g, " ")}`} type={currentType} currentItem={currentItem} updateList={() => getItemList()} />)}
        </PricingLayout>
    );
}

export default JobOpenings;