export const constants = {
    common: {
        dateFormat: 'dd/MM/yyyy',
        rangeDateFormat: 'dd/MM/yyyy-dd/MM/yyyy',
        limit: 10,
        page: 0,
        pageOptions: [
            { value: 10, name: "10 Items per page" },
            { value: 20, name: "20 Items per page" },
            { value: 50, name: "50 Items per page" },
            { value: 100, name: "100 Items per page" },
            { value: 150, name: "150 Items per page" },
            { value: 200, name: "200 Items per page" }
        ],
        trailDays: 15,
    },
    otherBenefitId: 42,
    regex: {
        password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=\-{}[\]|;":",.<>/?])/,
    },
    validations: {
        sameValidation: 'New and confirm password should be same',
        date: 'Date field is required',
        invalidEmail: 'invalid email',
        requiredEmail: 'Email is required.',
        requiredPassword: 'Password is required.',
        passwordValidate: 'Password must be 8 characters, atleast one uppercase, lowercase letter and number.',
    },
    actionTypes: {
        blockUnblock: 'blockUnblock',
        delete: 'delete',
    },
    userTypeId: {
        hm: 1,
        js: 0,
        admin: 2,
        guest: 3,
    },
    userTypesSort: {
        js: 'JS',
        hm: 'HM',
        both: 'Both',
        guest: 'Guest',
        admin: 'Admin',
    },
    statusTypeText: {
        active: 'Active',
        block: 'Block',
    },
    // COMPANY_SIZE: { 1: "1-50", 2: "50-100", 3: "100-500", 4: "500-1000", 5: "1000-5000", 6: "+5000", 7: "NO PREFERENCE" },
    JOB_TYPE: { 1: "FULL TIME", 2: "PART TIME", 3: "CONTRACT", 4: "No Preferences" },
    USER_TYPE: { 0: "Job Seeker", 1: "Hiring Manager", 2: "Admin", 3: "Guest User", 4: "Manager", 5: "Assistant" },
    COMPANY_TYPE: { START_UP: "Start Up", PUBLIC: "Public", PRIVATE: "Private", NO_PREFER: "No Preferences" },
    COMPANY_SIZE: { SMALL: "1-50", MEDIUM: "51-100", LARGE: "101-500", BIG: "501-1000", BIGGER: "1001-5000", BIGGEST: "+5000", NO_PREF: "No Preferences" },

    hiringQuestions: [
        {
            title: "hiring goal",
            link: "/lookups/hiring/goals",
        },
        {
            title: "department",
            link: "/lookups/hiring/departments",
        },
        {
            title: "seniority level",
            link: "/lookups/hiring/seniorities"
        },
        {
            title: "software language",
            link: "/lookups/hiring/languages"
        },
        {
            title: "management responsibility",
            link: "/lookups/hiring/responsibilities"
        },
        {
            title: "working style",
            link: "/lookups/hiring/working-style"
        },
        {
            title: "company size",
            link: "/lookups/hiring/company-size"
        },
        {
            title: "company type",
            link: "/lookups/hiring/company-type"
        },
        {
            title: "role experience",
            link: "/lookups/hiring/role-experience"
        },
        {
            title: "total work experience",
            link: "/lookups/hiring/total-experience"
        },
        {
            title: "education level",
            link: "/lookups/hiring/education-level"
        },
        // {
        //     title: "this job is",
        //     link: "/lookups/hiring/this-job-is"
        // },
        {
            title: "benefits we offer",
            link: "/lookups/hiring/benefits"
        },
        {
            title: "total compensation",
            link: "/lookups/hiring/total-compensation"
        }
    ],
    userTypeNormal: [{ name: 'Select user type', value: '' }, { name: 'Job Seeker', value: 0 }, { name: 'hiring manager', value: 1 }],
    userTypeInvites: [{ name: 'Select user type', value: '' }, { name: 'Job Seeker', value: 0 }, { name: 'hiring manager', value: 1 }, { name: 'Invited peer', value: 2 }],
    adminRole: [{ title: 'Select role', value: '' }, { title: 'manager', value: 0 }, { title: 'assistant', value: 1 }],
    // , { name: 'Guest', value: 3 }
    userType: [{ name: 'Select user type', value: '' }, { name: 'Job Seeker', value: 0 }, { name: 'hiring manager', value: 1 }, { name: 'Both', value: 2 }],
    statusType: [{ name: 'Select user status', value: '' }, { name: 'Active', value: 'active' }, { name: 'Block', value: 'block' }],
    statusTypeTwo: [{ name: 'Select status', value: '' }, { name: 'Active', value: 'active' }, { name: 'Expired', value: 'expired' }, { name: 'Blocked', value: 'blocked' }],
    statusTypeReport: [{ name: 'Select status', value: '' }, { name: 'open', value: 'open' }, { name: 'blocked', value: 'blocked' }, { name: 'resolved', value: 'resolved' }],
    statusHmAction: [{ name: "HM's action", value: 0 }, { name: 'Accepted', value: 1 }, { name: 'Rejected', value: 2 }],
    statusJsAction: [{ name: "JS's action", value: 0 }, { name: 'Accepted', value: 1 }, { name: 'Rejected', value: 2 }],
    statusAction: [{ name: "Select status", value: "" }, { name: 'accepted by js', value: 1 }, { name: 'rejected by js', value: 2 }, { name: 'accepted by hm', value: 3 }, { name: 'rejected by hm', value: 4 }],
    statusInterview: [{ name: 'Interview status', value: '' }, { name: 'Completed', value: 1 }, { name: 'Pending', value: 1 }, { name: 'Cancelled', value: 1 }, { name: 'No-interview', value: 1 }],
    statusJobType: [{ name: 'Select job status', value: '' }, { name: 'Active', value: 'active' }, { name: 'Expired', value: 'expired' }],
    subStatusJobType: [{ name: 'Select sub status', value: '' }, { name: 'Paid-full', value: 'active' }, { name: 'Paid-feedback', value: 'expired' }, { name: 'UnPaid', value: 'expired' }],
    statusFeedbackType: [{ name: 'feedback stage', value: 0 }, { name: 'Pre-interview', value: 1 }, { name: 'Post-interview', value: 2 }],
    statusPaymentType: [{ name: 'payment status', value: '' }, { name: 'paid-full', value: 0 }, { name: 'paid-feedback', value: 2 }, { name: 'unpaid', value: 'expired' }],
    optionWorking: [
        { value: 1, label: "work in an office" },
        { value: 2, label: "work from home" },
        { value: 3, label: "hybrid home/office work" },
        { value: 4, label: "no preference" }
    ],
    dashboardFilter: [
        { title: "quarterly", value: "quarterly" },
        { title: "monthly", value: "monthly" },
        { title: "weekly", value: "weekly" },
    ],
    pricingJobOpeningCategories: [
        {
            title: "hiring goal",
            type: "hiring_goal"
        },
        {
            title: "industry",
            type: "job_industry"
        },
        {
            title: "department",
            type: "job_department"
        },
        {
            title: "seniority level",
            type: "seniority_level"
        },
        {
            title: "responsibility",
            type: "job_responsibility"
        },
        {
            title: "role",
            type: "job_role"
        },
        {
            title: "software languages",
            type: "software"
        },
        {
            title: "location",
            type: "job_location"
        },
        {
            title: "total compensation",
            type: "job_compensation"
        },
        {
            title: "preferred working style",
            type: "job_working_style"
        }
    ],
    exportKeys: {
        users: 1,
        lookUps: 2,
    },
    exportKeysValue: {
        1: "users",
        2: "lookUps",
    },
    priceTabs: {
        industry: "industry",
        jobOpening: "job-opening",
        candidate: "candidate",
        softSkill: "soft-skill",
        ONLY_FEEDBACK: "ONLY-FEEDBACK"
    },
    priceCategories: {
        INDUSTRY: "INDUSTRY",
        TYPE: "TYPE",
        SIZE: "SIZE",
        HIRING_GOAL: "HIRING_GOAL",
        SENIORITY: "SENIORITY",
        SOFTWARE: "SOFTWARE",
        RESPONSIBILITY: "RESPONSIBILITY",
        EDUCATION_LEVEL: "EDUCATION-LEVEL",
        LOCATION: "LOCATION",
        COMPENSATION: "COMPENSATION",
        WORKING_STYLE: "WORKING STYLE",
        CERTIFICATES: "CERTIFICATES",
        VETERAN: "VETERAN",
        DEPARTMENT: "DEPARTMENT",
        ROLE: "ROLE",
        ROLE_EXPERIENCE: "ROLE EXPERIENCE",
        WORK_EXPERIENCE: "WORK EXPERIENCE",
        PATENTS: "PATENTS",
        SECURITY_CLEARANCE: "SECURITY CLEARANCE",
        ADDITIONAL_FEE: "ADDITIONAL-FEE",
        SOFT_SKILL_TEST: "SOFT-SKILL-TEST",
        GLOBAL: "GLOBAL",
    }
}