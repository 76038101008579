import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import JsJobTabs from '../components/js-job-tabs';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useCandidateFeedbackMutation } from '../../../../rtk/services/userApi';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import { intToBool } from '../../../../helper';

function JsJobFeedback(props) {
    const { currentHmMatch } = useSelector(({ common }) => common);
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateFeedbackMutation();
    // const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateFeedbacksMutation();
    useEffect(() => {
        getList({
            user_uuid: currentHmMatch?.user_details?.user_uuid,
            job_uuid: currentHmMatch?.job_uuid,
        });
    }, [getList, currentHmMatch]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <JsJobTabs />
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title d-flex align-items-center">
                            candidate {currentHmMatch?.user_details?.user_unique_name || "-"} - {currentHmMatch?.user_details?.candidate_first_name || "-"} {currentHmMatch?.user_details?.candidate_last_name}
                            <span className="badge rounded-pill bg-dark ms-2"><i className="bi-star text-warning me-2" />4.1</span>
                        </h5>
                    </div>
                    <div className="card-body pb-0">
                        {/*  */}
                        {item?.length > 0 && (
                            <div className="card mb-0">
                                <div className="card-header">
                                    <h5 className="card-title">Feedback by hiring manager</h5>
                                </div>
                                <div className="card-body">
                                    {/*  */}
                                    <div className="row mb-4">
                                        <div className="col-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="titleOne" className='pe-0'>
                                                    <input type="checkbox" id="titleOne" className="form-check-input me-2" checked />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <h6 className='text-white mb-2'>How accurate was this match?</h6>
                                            <p className='text-white mb-0'>{item?.hm_accuracy_title || "-"}</p>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="row mb-4">
                                        <div className="col-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="titleTwo" className='pe-0'>
                                                    <input type="checkbox" id="titleTwo" className="form-check-input me-2" checked />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <h6 className='text-white mb-2'>Would you like to continue interview?</h6>
                                            <p className='text-white mb-0'>{intToBool(item?.is_continue) ? "yes" : "no"}</p>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="titleTwo" className='pe-0 mb-0'>
                                                    <input type="checkbox" id="titleTwo" className="form-check-input me-2" checked />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <h6 className='text-white mb-0'>position closed</h6>
                                        </div>
                                    </div>
                                    {/*  */}
                                </div>
                            </div>
                        )}
                        {item?.length == 0 && (<div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div>)}
                        {/*  */}
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default JsJobFeedback;