import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

function HmJobSearchTabs(props) {
    const location = useLocation();
    const { currentJsMatch } = useSelector(({ common }) => common);
    const { job_uuid } = useParams();
    return (
        <div className="custom_tabs">
            <div className="nav-tabs nav full-width">
                <Link to={"/user/job-search/match/detail/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job-search/match/detail") ? "active" : ""}`}>job opening details</Link>
                <Link to={"/user/job-search/match/soft-skill/" + job_uuid + `?job_id=${currentJsMatch?.job_id}&user_id=${currentJsMatch?.user_details?.candidate_id}&job_history_id=${currentJsMatch?.user_details?.job_history_id}`} className={`nav-link ${location?.pathname?.includes("/user/job-search/match/soft-skill") ? "active" : ""}`}>soft skills match</Link>
                <Link to={"/user/job-search/match/interview-detail/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job-search/match/interview-detail") ? "active" : ""}`}>interview details</Link>
                <Link to={"/user/job-search/match/interview-feedback/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job-search/match/interview-feedback") ? "active" : ""}`}>feedback</Link>
            </div>
        </div >
    );
}

export default HmJobSearchTabs;