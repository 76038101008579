import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLogoutUserMutation } from '../../rtk/services/authApi';
import Loader from '../loader';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { customSwalFire, logoutHelper } from '../../helper';

function Topbar({ action }) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logoutUser, logoutUserRes] = useLogoutUserMutation();
    const handleLogout = () => {
        let title = "Logout?";
        let textMessage = "Are you sure you want to logout?";
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                logoutUser();
            }
        });
    }
    useEffect(() => {
        if (logoutUserRes.isSuccess) {
            toast.success(logoutUserRes?.data?.message);
            logoutHelper(dispatch, navigate);
        } else if (logoutUserRes?.error) {
            toast.error(logoutUserRes?.error?.data?.error);
        }
    }, [dispatch, logoutUserRes?.data?.message, logoutUserRes?.error, logoutUserRes.isSuccess, navigate])
    return (
        <>
            {logoutUserRes.isLoading && (<Loader />)}
            <div className="inner">
                <div className="hamburger">
                    <div className="hamburger_btn" onClick={() => action()}>
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
                <Dropdown className={`${location.pathname?.includes("profile") ? "show" : ""}`} >
                    <Dropdown.Toggle variant="null" id="dropdown-basic">
                        <img src="/assets/images/profile.jpg" alt="img" className="image-fit" />
                        <span>Hello, Admin</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/profile" className={`${location.pathname?.includes("profile") ? "active" : ""}`} > <i className="bi-person-circle me-2" />Profile</Dropdown.Item>
                        <Dropdown.Item as={"button"} onClick={handleLogout} > <i className="bi-box-arrow-right me-2" />Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}

export default Topbar;