import React from 'react';
import ReactPaginate from 'react-paginate';

function CommonPagination(props) {
    return (
        <div className='d-flex justify-content-end'>
            <ReactPaginate
                breakLabel="..."
                nextLabel="›"
                onPageChange={props.hitAction}
                pageCount={Math.ceil(props.total / props.pageLimit) || 0}
                previousLabel="‹"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination mb-0 mt-0"
                activeClassName="active"
                forcePage={Math.min(Math.max(0, Math.ceil(props.currentPage)), Math.ceil(props.total / props.pageLimit) - 1) || 0}
            />
        </div>
    );
}

export default CommonPagination;