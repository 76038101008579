import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
} from "redux-persist";

import storage from "redux-persist/lib/storage/session"; // defaults to sessionStorage for web
import authReducer from "./feature/authSlice";
import commonReducer from "./feature/commonSlice";
import { authApi } from "./services/authApi";
import { dashboardApi } from "./services/dashboardApi";
import { userApi } from "./services/userApi";
import { skillsApi } from "./services/skillsApi";
import { lookupsApi } from "./services/lookupsApi";
import { reportApi } from "./services/reportApi";
import { adminUserApi } from "./services/adminUserApi";
import { priceApi } from "./services/priceApi";
import { exportApi } from "./services/exportApi";
import { softSkillsApi } from "./services/softSkillsApi";

// const persistConfig = {
//     key: "root",
//     storage,
// };
const rootReducer = combineReducers({
    auth: persistReducer({ key: "auth", storage }, authReducer),
    common: persistReducer({ key: "common", storage }, commonReducer),
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [lookupsApi.reducerPath]: lookupsApi.reducer,
    [skillsApi.reducerPath]: skillsApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [adminUserApi.reducerPath]: adminUserApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [exportApi.reducerPath]: exportApi.reducer,
    [softSkillsApi.reducerPath]: softSkillsApi.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(authApi.middleware, dashboardApi.middleware, userApi.middleware, skillsApi.middleware, lookupsApi.middleware, reportApi.middleware, adminUserApi.middleware, priceApi.middleware, exportApi.middleware, softSkillsApi.middleware),
});

export const persistor = persistStore(store);